const actions = {
  SET_STATE: 'analytics/SET_STATE',
  ADMIN_ANALYTICS: 'analytics/ADMIN_ANALYTICS',
  TRADE_ANALYTICS_WEEK: 'analytics/TRADE_ANALYTICS_WEEK',
  TRADE_ANALYTICS_MONTH: 'analytics/TRADE_ANALYTICS_MONTH',
  TRADE_ANALYTICS_YEAR: 'analytics/TRADE_ANALYTICS_YEAR',
  ADMIN_LOGOUT_LOG: 'analytics/ADMIN_LOGOUT_LOG',
  ADMIN_LOGIN_LOG: 'analytics/ADMIN_LOGIN_LOG',

  PENDING_WITHDRAWALS_COUNT: 'analytics/PENDING_WITHDRAWALS_COUNT',
  TOTAL_TRADE_COUNT: 'analytics/TOTAL_TRADE_COUNT',
  PROCESSED_TRADE_COUNT: 'analytics/PROCESSED_TRADE_COUNT',
  DECLINED_TRADE_COUNT: 'analytics/DECLINED_TRADE_COUNT',
  PENDING_TRADE_COUNT: 'analytics/PENDING_TRADE_COUNT',
  TOTAL_USERS_COUNT: 'analytics/TOTAL_USERS_COUNT',
  TOTAL_ADMINS_COUNT: 'analytics/TOTAL_ADMINS_COUNT',
  TOTAL_RATES_COUNT: 'analytics/TOTAL_RATES_COUNT',
  TOTAL_TICKETS_COUNT: 'analytics/TOTAL_TICKETS_COUNT',
  UTILITIES_COUNT: 'analytics/UTILITIES_COUNT',
}

export default actions
