import { all, takeEvery, put, select, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore } from 'index'
import * as chats from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allChannels: chats.allChannels,
    takeUpSession: chats.takeUpSession,
	sendMessage:chats.sendMessage,
	getMessage:chats.getMessage,
	closeSession:chats.closeSession,
	getPushInstance:chats.getPushInstance
  },
}

export function* PUSHER_INSTANCE() {
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getPushInstance)
	// if (success.status === "success") {
	  yield put({
		type: 'chat/SET_STATE',
		payload: {
			pusher: success
		  },
	  })
	// }
}

const setChat = (sessionId) =>{
	// const msg = chatMessageName
	// msg.push({
	// 	owner: data.sender,
	// 	time: "5 min ago",
	// 	content: data.message})
	reduxStore.dispatch({
		type: 'chat/GET_MESSAGES',
		payload:{
			sessionId,
			ststus:"continue"
		}
	})
}

export function* CHATS() {
  yield put({
    type: 'chat/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allChannels)
  if (success.status === "success") {
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        chats: success.data.reverse(),
        all: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'chat/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TAKE_UP_SESSION({payload}) {
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const { pusher: pusherName } = yield select(state => state.chat)
	const success = yield call(mapAuthProviders[autProviderName].takeUpSession, payload)
	const channel = pusherName.subscribe(`presence-${payload.sessionId}`);
	channel.bind('client-newmessage', (data) => {
		setChat(payload.sessionId, data)
	})
	if (success.status === "success") {
		yield put({
			type: 'chat/CHATS',
		})
		notification.success({
			message: 'Session Joined',
			description: success.message,
		})
	}
	yield put({
		type: 'chat/SET_STATE',
		payload: {
		  channel,
		},
	})
}

export function* SEND_MESSAGE({payload}) {
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const { channel: channelName } = yield select(state => state.chat)
	const success = yield call(mapAuthProviders[autProviderName].sendMessage, payload)
	if (success.status === "success") {
	  channelName.trigger('client-newmessage', {message: payload.message})
	  yield put({
		type: 'chat/GET_MESSAGES',
		payload:{
			sessionId:payload.sessionId,
			ststus:"continue"
		}
	  })
	}else{
	  notification.success({
		message: 'error Occured',
		description: success,
	  })
	}
}

export function* GET_MESSAGES({payload}) {
	yield put({
	  type: 'chat/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const { pusher: pusherName } = yield select(state => state.chat)
	const success = yield call(mapAuthProviders[autProviderName].getMessage, payload)
	if(payload.status === "start"){
		const channel = pusherName.subscribe(`presence-${payload.sessionId}`);
		channel.bind('client-newmessage', (data) => {
			setChat(payload.sessionId, data)
		})
		yield put({
			type: 'chat/SET_STATE',
			payload: {
				channel,
			},
		})
	}
	if (success.status === "success") {
		yield put({
			type: 'chat/SET_STATE',
			payload: {
				loading:false,
				chatMessage:success.data
			},
		})
	}else{
		yield put({
			type: 'chat/SET_STATE',
			payload: {
				loading: false,
			},
		})
		notification.success({
			message: 'error Occured',
			description: success,
		})
	}
}

export function* CLOSE_SESSION({payload}) {
	const { channel: channelName } = yield select(state => state.chat)
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].closeSession, payload)
	if (success.status === "success") {
	  channelName.trigger('client-end-session', {message: "admin left...."})
	  yield put({
		type: 'chat/CHATS'
	  })
	  yield put({
		type: 'chat/SET_STATE',
		payload: {
		  channel:null,
		  chatMessage:[],
		  userData:null
		},
	  })
	  notification.success({
		message: 'Chat closed',
		description: success.message,
	  })
	}else{
	  notification.success({
		message: 'error Occured',
		description: success,
	  })
	}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHATS, CHATS),
    takeEvery(actions.TAKE_UP_SESSION, TAKE_UP_SESSION),
    takeEvery(actions.SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.CLOSE_SESSION, CLOSE_SESSION),
    takeEvery(actions.PUSHER_INSTANCE, PUSHER_INSTANCE),
    takeEvery(actions.GET_MESSAGES, GET_MESSAGES),
  ])
}
