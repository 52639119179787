import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore  } from 'index'
import * as banner from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    deleteBanner: banner.deleteBanner,
    uploadBanner: banner.uploadBanner,
    banners: banner.banners,
  },
}

export function* BANNERS() {
  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].banners)
  if (success.status === "success") {
    yield put({
		type: 'banner/SET_STATE',
		payload: {
		  banners: success.data.resources,
		  loading:false
		},
	  })
  }else{
	yield put({
	  type: 'banner/SET_STATE',
	  payload: {
		loading: false,
	  },
	})
  }
}

export function* UPLOAD_BANNER({ payload }) {
	yield put({
	  type: 'banner/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].uploadBanner, payload)
	if (success.status === "success") {
		yield reduxStore.dispatch({
			type: 'banner/BANNERS',
		})
	  notification.success({
		message: 'Banner Uploaded',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'banner/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export function* DELETE_BANNER({ payload }) {
	yield put({
	  type: 'banner/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].deleteBanner, payload)
	if (success.status === "success") {
	  yield reduxStore.dispatch({
		type: 'banner/BANNERS',
	  })
	  notification.success({
		message: 'Banner Deleted',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'banner/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.BANNERS, BANNERS),
    takeEvery(actions.UPLOAD_BANNER, UPLOAD_BANNER),
    takeEvery(actions.DELETE_BANNER, DELETE_BANNER),
  ])
}
