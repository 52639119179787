import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore  } from 'index'
import * as btc from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allBtcTrades: btc.allBtcTrades,
    createBtcTrade: btc.createBtcTrade,
    updateBtcTrade: btc.updateBtcTrade,
    deleteBtcTrade: btc.deleteBtcTrade,
	allUsersTransactions: btc.allUsersTransactions
  },
}

// fetch all btc transaction
export function* BTC() {
  yield put({
    type: 'btc/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allBtcTrades)
  if (success.status === "success") {
    yield put({
      type: 'btc/SET_STATE',
      payload: {
        btc: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'btc/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

// create new btc trade rate
export function* CREATE_BTC({payload}) {
  yield put({
    type: 'btc/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createBtcTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'btc/BTC',
    })
    notification.success({
      message: 'Btc Rate Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'btc/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'An error occured',
      description: success,
    })
  }
}

// update btc trade rate
export function* UPDATE_BTC({payload}) {
  yield put({
    type: 'btc/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateBtcTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'btc/BTC',
    })
    notification.success({
      message: 'Trade Rate Update',
      description: success.message,
    })
  }else{
    yield put({
      type: 'btc/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'An error occured',
      description: success,
    })
  }
}

// delete btc trade rate
export function* DELETE_BTC({payload}) {
  yield put({
    type: 'btc/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].deleteBtcTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'btc/BTC',
    })
    notification.success({
      message: 'Btc Rate deleted',
      description: success.message,
    })
  }else{
    yield put({
      type: 'btc/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'An error occured',
      description: success,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.BTC, BTC),
    takeEvery(actions.CREATE_BTC, CREATE_BTC),
    takeEvery(actions.DELETE_BTC, DELETE_BTC),
    takeEvery(actions.UPDATE_BTC, UPDATE_BTC),
  ])
}
