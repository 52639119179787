const actions = {
  SET_STATE: 'withdrawal/SET_STATE',
  WITHDRAWALS: 'withdrawal/WITHDRAWALS',
  TRANSACTIONS: 'withdrawal/TRANSACTIONS',
  GET_EXPORTS: 'withdrawal/GET_EXPORTS',
  SINGLE_WITHDRAWAL: 'withdrawal/SINGLE_WITHDRAWAL',
  TAKE_UP_WITHDRAWAL: 'withdrawal/TAKE_UP_WITHDRAWAL',
  UPDATE_WITHDRAWAL: 'withdrawal/UPDATE_WITHDRAWAL',
  REVERT_WITHDRAWAL: 'withdrawal/REVERT_WITHDRAWAL',
}

export default actions
