const actions = {
  SET_STATE: 'chat/SET_STATE',
  SEND_MESSAGE: 'chat/SEND_MESSAGE',
  GET_MESSAGES: 'chat/GET_MESSAGES',
  CHATS: 'chat/CHATS',
  TAKE_UP_SESSION: 'chat/TAKE_UP_SESSION',
  CLOSE_SESSION:'chat/CLOSE_SESSION',
  PUSHER_INSTANCE:'chat/PUSHER_INSTANCE'
}

export default actions
