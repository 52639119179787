const actions = {
  SET_STATE: 'card/SET_STATE',
  RATES: 'card/RATES',
  CREATE_RATE: 'card/CREATE_RATE',
  UPDATE_RATE: 'card/UPDATE_RATE',
  DISABLE_RATE: 'card/DISABLE_RATE',
  DELETE_RATE: 'card/DELETE_RATE',
  ALL_GIFT_CARD: 'card/ALL_GIFT_CARD',
  CARDS: 'card/CARDS',
  ALL_COUNTRY: 'card/ALL_COUNTRY',
  UPDATE_START_WINDOW: 'card/UPDATE_START_WINDOW',
  CREATE_GIFT_CARD: 'card/CREATE_GIFT_CARD',
  UPDATE_GIFT_CARD: 'card/UPDATE_GIFT_CARD',
}

export default actions
