import apiClient from 'services/axios'
import axios from 'axios';
// import store from 'store'
import { getConfig } from 'services/config/config'

export async function login(email, password) {
  const response = axios({
    method: 'post',
    url: getConfig("adminlogin"),
    data: {
      email,
      password
    },
	withCredentials: true
  })
    .then(res => {
      if (res) {
        return res.data
      }
      return false
    }).catch(err => err)
	return response
}

export async function register(email, password, name) {
  const response = apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(res => {
      if (res) {
        // const { accessToken } = res.data
        // if (accessToken) {
        //   store.set('accessToken', accessToken)
        // }
        return res.data
      }
      return false
    })
    .catch(err => err)
	return response
}

export async function forgotPassword(email){
  return axios({
    method: 'post',
    url: getConfig("forgotPassword"),
    data: {email},
	withCredentials: true
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    }).catch(err => err.response.data)
}

export async function resetPassword(data){
  return axios({
    method: 'post',
    url: getConfig("resetPassword"),
    data,
	withCredentials: true
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    }).catch(err => err.response.data)
}

export async function resendActivationCode(email){
  return axios({
    method: 'post',
    url: getConfig("resendActivationCode"),
    data: {email},
	withCredentials: true
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    }).catch(err => err.response.data)
}

export async function getBitPay(){
	return axios({
	  method: 'get',
	  url: 'https://bitpay.com/api/rates',
	})
	  .then(response => {
		if (response) {
		  return response.data
		}
		return false
	  }).catch(err => err.response.data)
  }

export async function logout() {
  localStorage.removeItem("data");
  localStorage.removeItem("roles");
  localStorage.removeItem("deviceId");
  localStorage.removeItem("email");
    return true
}

// export async function currentAccount() {
//   return apiClient
//     .get('/auth/account')
//     .then(response => {
//       if (response) {
//         const { accessToken } = response.data
//         if (accessToken) {
//           store.set('accessToken', accessToken)
//         }
//         return response.data
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }
