import actions from './actions'

const initialState = {
  data: [],
  all:[],
  exports:[],
  rates:[],
  loading:false,
  fetching:false,
}

export default function utilitiesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
