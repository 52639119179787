const actions = {
  SET_STATE: 'admin/SET_STATE',
  ROLES: 'admin/ROLES',
  REMARKS: 'admin/REMARKS',
  CREATE_REMARK: 'admin/CREATE_REMARK',
  DELETE_REMARK: 'admin/DELETE_REMARK',
  USERS: 'admin/USERS',
  SEARCH_USERS: 'admin/SEARCH_USERS',
  GET_EXPORTS: 'admin/GET_EXPORTS',
  SINGLE_USERS: 'admin/SINGLE_USERS',
  BANK_DETAILS: 'admin/BANK_DETAILS',
  ADMINS: 'admin/ADMINS',
  CREATE_ADMIN: 'admin/CREATE_ADMIN',
  CREATE_ROLE: 'admin/CREATE_ROLE',
  UPDATE_ADMIN: 'admin/UPDATE_ADMIN',
  SETUP_PROFILE: 'admin/SETUP_PROFILE',
  UPDATE_ADMIN_STATUS: 'admin/UPDATE_ADMIN_STATUS',
  UPDATE_ADMIN_ROLE: 'admin/UPDATE_ADMIN_ROLE',
  DELETE_ADMIN_ROLE: 'admin/DELETE_ADMIN_ROLE',
  ACTIVATE_ACCOUNT: 'admin/ACTIVATE_ACCOUNT',
  SET_PASSWORD: 'admin/SET_PASSWORD',
  SET_NOTIFICATION: 'admin/SET_NOTIFICATION',
  UPDATE_USER: 'admin/UPDATE_USER',
  CREATE_USER: 'admin/CREATE_USER',
  ENABLE_OR_DISABLE_2FA:"admin/ENABLE_OR_DISABLE_2FA",
  USER_TRANSACTIONS:"admin/USER_TRANSACTIONS",
  WALLET_TRANSACTIONS:"admin/WALLET_TRANSACTIONS",
  RESEND_VERIFICATION_MAIL: 'user/RESEND_VERIFICATION_MAIL',
}

export default actions
