/* eslint no-nested-ternary: 0 */
import React from 'react'
import { Empty, Tabs } from 'antd'
import { Link } from 'react-router-dom'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
import style from './style.module.scss'

const mapStateToProps = ({ dispatch }) => ({dispatch })
const { TabPane } = Tabs

const List2 = ({notifications}) => {

//   const data = JSON.parse(localStorage.getItem("data"));
//   const deviceId = localStorage.getItem("deviceId");
//   const handleTurnOn =(type)=>{
//     const fd = new FormData();
//     if(type === "set"){
//       console.log(data.deviceId)
//       fd.append('deviceId', deviceId);
//     }else{
//       fd.append('deviceId', "");
//       console.log("hello", data.deviceId)
//     }
//     dispatch({
//       type: 'admin/SET_NOTIFICATION',
//       payload: fd,
//     })
//   }
  return (
    <div>
      <div style={{display:"flex", justifyContent:"space-between", padding:20}}>
        <Link to="/notifications"><button className="btn btn-sm btn-primary" type="button">All Notification</button></Link>
        {/* <i className={data.deviceId !== null ?"fe fe-bell-off": "fe fe-bell"} tabIndex="-1" role="button" onKeyPress={()=>handleTurnOn()} onClick={data.deviceId === "" || data.deviceId === null?()=>handleTurnOn("set"):()=>handleTurnOn("unset")} /> */}
      </div>
      <Tabs className={`${style.tabs} kit-tabs-bordered`} defaultActiveKey="1">
        <TabPane tab="Notifications" key="1">
          <div className="text-gray-6">
            <ul className="list-unstyled">
              {notifications.length === 0?
                <Empty />:
                notifications.map(note =>{
                  const {header, _id, createdAt} = note
                return(
                  <Link to={`/notification-details/${_id}`} key={_id}>
                    <li className="mb-3">
                      <div className={style.head}>
                        <time className={style.time}><TimeAgo date={createdAt} /></time>
                      </div>
                      <p>{header}</p>
                    </li>
                  </Link>
              )})}
            </ul>
          </div>
        </TabPane>
        {/* <TabPane tab="Events" key="2">
          <div className="text-center mb-3 py-4 bg-light rounded">No Events</div>
        </TabPane>
        <TabPane tab="Actions" key="3">
          <div className="text-center mb-3 py-4 bg-light rounded">No Actions</div>
        </TabPane> */}
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(List2)
