const actions = {
  SET_STATE: 'trade/SET_STATE',
  TICKETS: 'trade/TICKETS',
  TICKETS_CATEGORY: 'trade/TICKETS_CATEGORY',
  CREATE_TICKET: 'trade/CREATE_TICKET',
  UPDATE_TICKET: 'trade/UPDATE_TICKET',
  CREATE_CATEGORY: 'trade/CREATE_CATEGORY',

  REMARKS: 'trade/REMARKS',
  CREATE_TRADE: 'trade/CREATE_TRADE',
  CREATE_AGENT_TRADE: 'trade/CREATE_AGENT_TRADE',
  UPDATE_TRADE_STATUS: 'trade/UPDATE_TRADE_STATUS',
  UPDATE_ADMIN_TRADE: 'trade/UPDATE_ADMIN_TRADE',
  TAKE_UP_TRADE: 'trade/TAKE_UP_TRADE',
  RATE_TRADE: 'trade/RATE_TRADE',
  TRADES: 'trade/TRADES',
  TRANSACTIONS: 'trade/TRANSACTIONS',
  GET_EXPORTS: 'trade/GET_EXPORTS',
  SINGLE_TRADE: 'trade/SINGLE_TRADE',
  CONTINUE_TRADE_STATUS:"trade/CONTINUE_TRADE_STATUS",
  ATTENTION_TRADE_STATUS:"trade/ATTENTION_TRADE_STATUS",
  TRADE_ANALYTICS: 'trade/TRADE_ANALYTICS',
  SINGLE_TRADE_ANALYTICS: 'trade/SINGLE_TRADE_ANALYTICS',
  DOWNLOAD_TRADE_ANALYTICS: 'trade/DOWNLOAD_TRADE_ANALYTICS',
  SEARCH_TRANSACTIONS:'trade/SEARCH_TRANSACTIONS',

  CHANGE_TOGGLE:"trade/CHANGE_TOGGLE",
}

export default actions
