import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import admin from './admin/reducers'
import trade from './trade/reducers'
import card from './card/reducers'
import btc from './btc/reducers'
import loader from './loader/reducers'
import message from './message/reducers'
import banner from './banner/reducers'
import utilities from './utilities/reducers'
import chat from './chat/reducers'
import withdrawal from './withdrawal/reducers'
import withdrawalSettings from './withdrawal-settings/reducers'
import analytics from './analytics/reducers'
import device from './device/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    admin,
    trade,
    card,
    btc,
	loader,
	message,
	banner,
	utilities,
	chat,
	withdrawal,
	withdrawalSettings,
	analytics,
	device
  })
