import actions from './actions'

const data = JSON.parse(localStorage.getItem("data"));
const initialState = {
  admins: [],
  roles: [],
  remarks:[],
  users: [],
  exports: [],
  all: [],
  singleUser:{},
  bankData:{},
  transactions:[],
  walletTransactions:[],
  loading:false,
  fetching:false,
  avatar: data && data.photo,
}

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
