import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import admin from './admin/sagas'
import withdrawalSettings from './withdrawal-settings/sagas'
import trade from './trade/sagas'
import card from './card/sagas'
import btc from './btc/sagas'
import loader from './loader/sagas'
import message from './message/sagas'
import banner from './banner/sagas'
import utilities from './utilities/sagas'
import chat from './chat/sagas'
import withdrawal from './withdrawal/sagas'
import analytics from './analytics/sagas'
import device from './device/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), admin(), trade(), card(), btc(), loader(), message(), banner(), utilities(), chat(), withdrawal(), withdrawalSettings(), analytics(), device()])
}
