import actions from './actions'

const initialState = {
  data: [],
  weekTrade: [],
  monthTrade: [],
  yearTrade: [],
  loginLog: [],
  logoutLog: [],
  all:[],
  loading:false,
  utilities:0,
  pendingWithdrawals:0,
  totalTrade:[],
  processedTrade:[],
  declinedTrade:[],
  pendingTrade:[],
  users:0,
  admins:0,
  rates:0,
  tickets:0

}

export default function analyticsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
