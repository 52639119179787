/* eslint no-underscore-dangle: 0 */
import { history} from 'index'
import { getConfig } from 'services/config/config';
import {logout} from 'services/jwt'
import axios from 'axios';
import Pusher from 'pusher-js';

function authHeader() {
  const data = JSON.parse(localStorage.getItem('data'));
  if (data) {
      return {
        'authorization': data.token,
        'Access-Control-Allow-Origin' : '*'
      };
  }
  return {}
}

export async function loginLog() {
	const requestOptions = {
		method: "POST",
		headers: {
		  ...authHeader(), "Content-Type": "application/json" ,
    },
		body:JSON.stringify({macAddress:"4580vxeruwjy12565jf20348543"})
	};
	return fetch(`${getConfig("loginLog")}`, requestOptions).then(handleResponse)
}

export async function logoutLog() {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		body:JSON.stringify({macAddress:"4580vxeruwjy12565jf20348543"})
	};
	return fetch(getConfig("logoutLog"), requestOptions).then(handleResponse)
}

export async function admins() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("allAdmins")}`, requestOptions).then(handleResponse)
}

export async function updateAdminStatus(data) {
  const {id, type} = data
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body:JSON.stringify(data)
  };
  return fetch(`${getConfig("updateAdminStatus")}${type}?adminId=${id}`, requestOptions).then(handleResponse);
}

export async function roles() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allRoles"), requestOptions).then(handleResponse);
}

export async function allRemarks() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json", "credentials": "same-origin"},
  };
  return fetch(getConfig("allRemarks"), requestOptions).then(handleResponse);
}

export async function singleRemark(remarkType) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("singleRemark")}${remarkType}`, requestOptions).then(handleResponse);
}

export async function createRemark(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify({remarkType:data.remarkType, remarks:[data.remarks]})
  };
  return fetch(getConfig("createRemark"), requestOptions).then(handleResponse);
}

export async function deleteRemark(data) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createRemark"), requestOptions).then(handleResponse);
}

export async function users(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("allUsers")}users${data}`, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function searchUsers(data) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("searchUsers")}${data}`, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function enableOrDisable2Fa(data) {
  const {type, phoneNumber} = data
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify({phoneNumber})
  };
  return fetch(getConfig("enableOrDisable2Fa") + type, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function singleUser(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allUsers")+ id, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function bankDetails(id) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("bankDetails")}${id}`, requestOptions).then(handleResponse);
}

export async function createAdmin(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createAdmin"), requestOptions).then(handleResponse);
}

export async function createRole(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify({adminRole:data})
  };
  return fetch(getConfig("createRole"), requestOptions).then(handleResponse);
}

export async function updateAdmin(data) {
  return axios.put(getConfig("updateAdmin"), data, {headers: { ...authHeader()}})
  .then(res => {
    return(res.data)
  })
  .catch(err => (err));
}

export async function updateWithdrawalRate(data) {
  return axios.put(getConfig("updateWithdrawalRate"), data, {headers: { ...authHeader()}})
    .then(res => {
      return(res.data)
    })
    .catch(err => (err));
}

export async function getWithdrawalRate() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("getWithdrawalRate")}`, requestOptions).then(handleResponse);
}

export async function setupProfile(data) {
	const val = {
        phone:data.phone,
        firstName:data.firstName,
        lastName:data.lastName,
        password:data.password}
	const requestOptions = {
		method: "PUT",
		headers: {"Content-Type": "application/json" },
		body:JSON.stringify(val)
	  };
	  return fetch(`${getConfig("setupProfile")}${data.email}`, requestOptions).then(handleResponse);
}

export async function activateAccount(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("activateAccount"), requestOptions).then(handleResponse);
}

export async function setPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("setPassword"), requestOptions).then(handleResponse);
}

export async function updateAdminRole(data) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("updateAdminRole"), requestOptions).then(handleResponse);
}

export async function deleteAdminRole(data) {
  console.log(data)
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("deleteAdminRole"), requestOptions).then(handleResponse);
}

export async function allTickets() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allTickets"), requestOptions).then(handleResponse);
}

export async function allCategory() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allCategory"), requestOptions).then(handleResponse);
}

export async function createTicket(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createTicket"), requestOptions).then(handleResponse);
}

export async function updateTicket(data) {
  const {id, status} = data
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("updateTicket")+id +"&status="+ status, requestOptions).then(handleResponse);   // eslint-disable-line prefer-template
}

export async function createCategory(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createCategory"), requestOptions).then(handleResponse);
}


export async function allTrade(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("allTrade")}?${data}`, requestOptions).then(handleResponse);
}

export async function singleTrade(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allTrade") + "?limit=1&tradeId=" + id, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function updateTradeStatus(data) {
  const {id, status, fd} = data
  return axios.put(`${getConfig("updateTradeStatus")}${id}&status=${status}`, fd, {headers: { ...authHeader()}})
  .then(res => {
	  return(res.data)
  })
  .catch(err => err);
}

export async function createTrade(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createTrade"), requestOptions).then(handleResponse);
}

export async function createAgentTrade(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createAgentTrade"), requestOptions).then(handleResponse);
}

export async function updateAdminTrade(data) {
  const {_id} = data
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("updateAdminTrade")+_id , requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function takeUpTrade(data) {
  const {id, user} = data
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("takeUpTrade")+id +"&adminId="+ user, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function rateTrade(data) {
  const {user} = data
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("rateTrade")+user, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function userTradeTransactions(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // 1&skip=1&userId=5fa3ef970f0e155f6c00b8a8&status=pending
  };
  return fetch(`${getConfig("userTradeTransactions")}?userId=${id}`, requestOptions).then(handleResponse);
}

// cards and rates api calls

export async function allRates() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allRate"), requestOptions).then(handleResponse);
}

export async function createRate(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createRate"), requestOptions).then(handleResponse);
}

export async function updateRate(data) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify({"update":data})
  };
  return fetch(getConfig("updateRate"), requestOptions).then(handleResponse);
}

export async function updateStartWindow(data) {
	const requestOptions = {
	  method: "PUT",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	  body:JSON.stringify(data)
	};
	return fetch(getConfig("updateStartWindow"), requestOptions).then(handleResponse);
}

export async function disableRate(data) {
	const {id} = data
	const requestOptions = {
	  method: "PUT",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	  body:JSON.stringify(data)
	};
	return fetch(`${getConfig("disableRate")}${id}`, requestOptions).then(handleResponse);
}

export async function deleteRate(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("deleteRate")+id, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function allGiftCard() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allGiftCard"), requestOptions).then(handleResponse);
}

export async function allCard() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(getConfig("allCard"), requestOptions).then(handleResponse);
}

export async function createGiftCard(data) {
  return axios.post(getConfig("createGiftCard"), data, {headers: { ...authHeader()}})
	.then(res => {
		return(res.data)
	})
	.catch(err => {
		console.log(err)
    return err
	});
}

export async function updateGiftCard(data) {
  return axios.put(getConfig("updateGiftCard"), data, {headers: { ...authHeader()}})
	.then(res => {
		return(res.data)
	})
	.catch(err => {
		console.log(err)
    return err
	});
  // const requestOptions = {
  //   method: "PUT",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body:JSON.stringify(data)
  // };
  // return fetch(getConfig("updateGiftCard"), requestOptions).then(handleResponse);
}

export async function allCountries() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allCountries"), requestOptions).then(handleResponse);
}

export async function updateUser(data) {
  const {id} = data
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  return fetch(`${getConfig("updateUser")}${id}`, requestOptions).then(handleResponse);
}

export async function createUser(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  return fetch(getConfig("createUser"), requestOptions).then(handleResponse);
}

export async function allNotifications(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("allNotifications")}${id}&isAdmin=true`, requestOptions).then(handleResponse);
}

export async function updateNotification(data) {
  const {id, status} = data
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  return fetch(`${getConfig("updateNotification")}${id}&status=${status}`, requestOptions).then(handleResponse);
}

export async function userTransactions(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("userTransactions")}?userId=${id}`, requestOptions).then(handleResponse);
}

export async function allBtcTrades() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("allBtcTrades"), requestOptions).then(handleResponse);
}

export async function createBtcTrade(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("createBtcTrade"), requestOptions).then(handleResponse);
}

export async function updateBtcTrade(data) {
  const {id} = data
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(`${getConfig("updateBtcTrade")}${id}`, requestOptions).then(handleResponse);
}

export async function deleteBtcTrade(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(`${getConfig("deleteBtcTrade")}${id}`, requestOptions).then(handleResponse);
}

export async function allLoader() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(getConfig("loader"), requestOptions).then(handleResponse);
}

export async function createLoader(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(getConfig("loader"), requestOptions).then(handleResponse);
}

export async function updateLoader(data) {
  const {id} = data
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(data)
  };
  return fetch(`${getConfig("updateLoader")}${id}`, requestOptions).then(handleResponse);
}

export async function deleteLoader(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(`${getConfig("deleteLoader")}${id}`, requestOptions).then(handleResponse);
}

export async function resendVerificationMail(email){
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body:JSON.stringify(email)
  };
  return fetch(getConfig("resendActivationCode"), requestOptions).then(handleResponse);
}

export async function tradeAnalytics(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("tradeAnalytics")}?${data}`, requestOptions).then(handleResponse);
}

export async function singleTradeAnalytics(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("tradeAnalytics")}?trade=${id}`, requestOptions).then(handleResponse);
}

export async function downloadCsv() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${getConfig("tradeAnalytics")}?downloadCsv=true`, requestOptions).then(handleResponse);
}

export async function banners() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(getConfig("banner"), requestOptions).then(handleResponse);
}

export async function allWithdrawals(data) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("allWithdrawals")}?${data}`, requestOptions).then(handleResponse);
}

export async function tradeSummary(data) {
	const requestOptions = {
	  method: "POST",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	  body:JSON.stringify(data)
	};
	return fetch(getConfig("tradeSummary"), requestOptions).then(handleResponse);
}

export async function tradeProcessingTime(data) {
	const requestOptions = {
	  method: "POST",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	  body:JSON.stringify(data)
	};
	return fetch(getConfig("tradeProcessingTime"), requestOptions).then(handleResponse);
}

export async function getLogOutLog(data) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("getLog")}${data.type}&userId=${data.id}`, requestOptions).then(handleResponse);
}

export async function takeUpWithdrawal(id) {
	const requestOptions = {
	  method: "POST",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	//   body:JSON.stringify(data)
	};
	return fetch(`${getConfig("takeUpWithdrawal")}?requestId=${id}`, requestOptions).then(handleResponse);
}

export async function updateWithdrawal(data) {
	const {id, status} = data
	const requestOptions = {
	  method: "PUT",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("updateWithdrawal")}?requestId=${id}&status=${status}`, requestOptions).then(handleResponse);
}

export async function revertWithdrawal(data) {
	const requestOptions = {
	  method: "POST",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	  body: JSON.stringify(data)
	};
	return fetch(getConfig("revertWithdrawal"), requestOptions).then(handleResponse);
}

export async function deleteBanner(data) {
	const requestOptions = {
	  method: "DELETE",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	//   body:JSON.stringify(data)
	};
	return fetch(`${getConfig("banner")}?fileName=${data}`, requestOptions).then(handleResponse);
}

export async function uploadBanner(data) {
	return axios.post(getConfig("banner"), data, {headers: { ...authHeader()}})
	.then(res => {
		return(res.data)
	})
	.catch(err => {
		console.log(err)
	});
}

export async function sendBroadcast(data) {
	let msg = {}
	let api = ""
	if(data.group === "All" || data.group === "select user"){
		api = "sendBroadcast"
		msg = {
			notification : {
				header : data.title,
				body : data.body.blocks[0].text
			},
			users : data.id
		}
	}else{
		api = "sendBroadcastGroup"
		msg = {
		    userGroup:data.group,
			notification : {
				header : data.title,
				body : data.body.blocks[0].text
			},
		}
	}

	const requestOptions = {
	  method: "POST",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	  body:JSON.stringify(msg)
	};
	return fetch(getConfig(api), requestOptions).then(handleResponse);
}

export async function singleWithdraw(id) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("allWithdrawals")}?limit=1&requestId=${id}`, requestOptions).then(handleResponse); // eslint-disable-line prefer-template
}

export async function allUsersTransactions(params) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("userTransactions")}?${params}`, requestOptions).then(handleResponse);
}

export async function searchTransactions(params) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("searchTransactions")}${params}`, requestOptions).then(handleResponse);
}

export async function searchTradeTransactions(params) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("searchTradeTransactions")}${params}`, requestOptions).then(handleResponse);
}

export async function allChannels() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" }
	};
	return fetch(`${getConfig("allChannels")}`, requestOptions).then(handleResponse);
}

export const takeUpSession = async (data)=>{
	const {sessionId} = data
	const result = await axios({
		method: 'POST',
		url: getConfig("takeUpSession"),
		headers: authHeader(),
		data:{sessionId}
	});
	return result.data
	// return fetch(getConfig("takeUpSession"), requestOptions).then(handleResponse);
}

export const sendMessage = async (data)=>{
	const result = await axios({
		method: 'POST',
		url: getConfig("sendMessage"),
		headers: authHeader(),
		data
	});
	return result.data
}

export const getMessage = async (data)=>{
	const {sessionId} = data
	const result = await axios({
		method: 'GET',
		url: `${getConfig("sendMessage")}?sessionId=${sessionId}`,
		headers: authHeader(),
		// data
	});
	return result.data
}

export async function getAdminDevices() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(getConfig("getAdminDevices"), requestOptions).then(handleResponse);
}

export async function updateAdminDevice(data) {
	const {status, id} = data
	const requestOptions = {
	  method: "PUT",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("updateAdminDevice")}${id}&status=${status}`, requestOptions).then(handleResponse);
}

export async function getUtilitiesCount(data) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("getUtilitiesCount")}?${data}`, requestOptions).then(handleResponse);
}

export async function getWithdrawalsCount(data) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("getWithdrawalsCount")}?${data}`, requestOptions).then(handleResponse);
}

export async function getTradesCount(data) {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(`${getConfig("getTradesCount")}${data}`, requestOptions).then(handleResponse);
}

export async function getTicketsCount() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(getConfig("getTicketsCount"), requestOptions).then(handleResponse);
}

export async function getRatesCount() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(getConfig("getRatesCount"), requestOptions).then(handleResponse);
}

export async function getAdminsCount() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(getConfig("getAdminsCount"), requestOptions).then(handleResponse);
}

export async function getUsersCount() {
	const requestOptions = {
	  method: "GET",
	  headers: { ...authHeader(), "Content-Type": "application/json" },
	};
	return fetch(getConfig("getUsersCount"), requestOptions).then(handleResponse);
}

export const closeSession = async (sessionId)=>{
	const result = await axios({
		method: 'POST',
		url: getConfig("closeSession"),
		headers: authHeader(),
		data:sessionId
	});
	return result.data
}

export const getPushInstance = ()=>{
	const data = JSON.parse(localStorage.getItem('data'));
	// Pusher.logToConsole = true;
	const pusher = new Pusher('f70f58d51243ccbbf9a7', {
		cluster: 'eu',
		authEndpoint: 'https://server.zabira.ng/api/v1/pusher/auth',
		auth: {
		  headers: {'authorization': data.token}
		}
	  });
	  return pusher
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data = "";
    try {
      data = text && JSON.parse(text);
    } catch (error1) {
      // ...
    }
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      if (error === "jwt expired") {
        logout()
        history.push("/auth/login");
      }
      return Promise.reject(error);
    }

    return data;
  }).catch(err => err)
}

