import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Layout from 'layouts'
import { connect } from 'react-redux'

const routes = [
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/izikjon/alpha')),
    exact: true,
  },
  {
    path: '/admin-devices',
    Component: lazy(() => import('pages/izikjon/devices')),
    exact: true,
  },
  {
    path: '/admins',
    Component: lazy(() => import('pages/izikjon/admin')),
    exact: true,
  },
  {
    path: '/trade',
    Component: lazy(() => import('pages/izikjon/trade')),
    exact: true,
  },
  {
    path: '/trade-detail/:id',
    Component: lazy(() => import('pages/izikjon/trade/trade-detail')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/izikjon/profile')),
    exact: true,
  },
  {
    path: '/withdrawal-settings',
    Component: lazy(() => import('pages/izikjon/withdrawal-settings')),
    exact: true,
  },
  {
    path: '/tickets',
    Component: lazy(() => import('pages/izikjon/ticket')),
    exact: true,
  },
  {
    path: '/btc',
    Component: lazy(() => import('pages/izikjon/btc')),
    exact: true,
  },
  {
    path: '/vendors',
    Component: lazy(() => import('pages/izikjon/loader')),
    exact: true,
  },
  {
    path: '/remarks',
    Component: lazy(() => import('pages/izikjon/role/remark')),
    exact: true,
  },
  {
    path: '/roles',
    Component: lazy(() => import('pages/izikjon/role')),
    exact: true,
  },
  {
    path: '/cards',
    Component: lazy(() => import('pages/izikjon/cards')),
    exact: true,
  },
  {
    path: '/all-gift-cards',
    Component: lazy(() => import('pages/izikjon/cards/all-gift-cards')),
    exact: true,
  },
  {
    path: '/users',
    Component: lazy(() => import('pages/izikjon/users')),
    exact: true,
  },
  {
    path: '/user-details/:id',
    Component: lazy(() => import('pages/izikjon/users/user-details')),
    exact: true,
  },
  {
    path: '/notifications',
    Component: lazy(() => import('pages/izikjon/notifications')),
    exact: true,
  },
  {
    path: '/notification-details/:id',
    Component: lazy(() => import('pages/izikjon/notifications/notification-details')),
    exact: true,
  },
  {
    path: '/withdrawal-request',
    Component: lazy(() => import('pages/izikjon/withdrawal')),
    exact: true,
  },
  {
    path: '/gift-cards',
    Component: lazy(() => import('pages/izikjon/transactions')),
    exact: true,
  },
  {
    path: '/airtime',
    Component: lazy(() => import('pages/izikjon/utilities')),
    exact: true,
  },
  {
    path: '/btc-transaction',
    Component: lazy(() => import('pages/izikjon/utilities/btc')),
    exact: true,
  },
  {
    path: '/cable',
    Component: lazy(() => import('pages/izikjon/utilities/cable')),
    exact: true,
  },
  {
    path: '/electricity',
    Component: lazy(() => import('pages/izikjon/utilities/electricity')),
    exact: true,
  },
  {
    path: '/data',
    Component: lazy(() => import('pages/izikjon/utilities/data')),
    exact: true,
  },
  {
    path: '/withdrawal',
    Component: lazy(() => import('pages/izikjon/utilities/withdrawal')),
    exact: true,
  },
  {
    path: '/withdrawal-details/:id',
    Component: lazy(() => import('pages/izikjon/withdrawal/withdrawal-detail')),
    exact: true,
  },
  {
    path: '/transaction-details/:id',
    Component: lazy(() => import('pages/izikjon/transactions/transaction-details')),
    exact: true,
  },
  {
    path: '/chat',
    Component: lazy(() => import('pages/izikjon/messaging')),
    exact: true,
  },
  {
    path: '/broadcast',
    Component: lazy(() => import('pages/izikjon/broadcast')),
    exact: true,
  },
  {
    path: '/upload-banner',
    Component: lazy(() => import('pages/izikjon/banner')),
    exact: true,
  },
  {
    path: '/admin-analytics',
    Component: lazy(() => import('pages/izikjon/analytics')),
    exact: true,
  },
  {
    path: '/trade-analytics',
    Component: lazy(() => import('pages/izikjon/analytics/TradeAnalytic')),
    exact: true,
  },
  {
    path: '/analytics-details/:id',
    Component: lazy(() => import('pages/izikjon/analytics/adminAnalyticsDetails')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/activation',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/activate-account',
    Component: lazy(() => import('pages/auth/activate-account')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
