/* eslint no-underscore-dangle: 0 */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as withdrawalSettings from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    updateWithdrawalRate:withdrawalSettings.updateWithdrawalRate,
    getWithdrawalRate:withdrawalSettings.getWithdrawalRate,
  },
}

export function* RESET_ISUPDATED(){
  yield put({
    type: 'withdrawal-settings/SET_STATE',
    payload: {
      isUpdated: false,
    },
  })
}

export function* GET_WITHDRAWAL_RATE({payload}) {
    yield put({
    type: 'withdrawal-settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders.jwt.getWithdrawalRate, payload)
  if (success.status === "success") {
    /*  localStorage.setItem("WS", JSON.stringify({
      withdrawalFee: success.data.withdrawalFee,
      withdrawalMinimum: success.data.withdrawalMinimum,
      withdrawalMaximum: success.data.withdrawalMaximum,
      withdrawalMethod: success.data.withdrawalMethod
    })) */
    /*  localStorage.setItem("WSS", JSON.stringify({
      withdrawalLoading: false
    })) */
      yield put({
      type: 'withdrawal-settings/SET_STATE',
      payload: {
        withdrawalSettings: success.data,
        loading:false
      },
    })
  }else{
      yield put({
      type: 'withdrawal-settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_WITHDRAWAL_RATE({ payload }) {
    yield put({
    type: 'withdrawal-settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateWithdrawalRate, payload)

  if (success.status === "success") {
    yield put({
      type: 'withdrawal-settings/SET_STATE',
      payload: {
        loading:false,
        withdrawalSettings:success.data,
        isUpdated: true
      },

    })
    notification.success({
      message: 'Withdrawal Rate Update',
      description: success.message,
    })
  }else{
      yield put({
      type: 'withdrawal-settings/SET_STATE',
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Error Occurred',
      description: success,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_WITHDRAWAL_RATE, UPDATE_WITHDRAWAL_RATE),
    takeEvery(actions.GET_WITHDRAWAL_RATE, GET_WITHDRAWAL_RATE),
    takeEvery(actions.RESET_ISUPDATED, RESET_ISUPDATED)
  ])
}
