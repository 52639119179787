import { all, takeEvery, put, call, select } from 'redux-saga/effects'
// import { notification } from 'antd'
// import { store as reduxStore } from 'index'
import * as analytics from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    tradeProcessingTime: analytics.tradeProcessingTime,
	tradeSummary: analytics.tradeSummary,
	getLogOutLog:analytics.getLogOutLog,
	getUtilitiesCount:analytics.getUtilitiesCount,
	getWithdrawalsCount:analytics.getWithdrawalsCount,
	getTradesCount:analytics.getTradesCount,
	getTicketsCount:analytics.getTicketsCount,
	getRatesCount:analytics.getRatesCount,
	getAdminsCount:analytics.getAdminsCount,
	getUsersCount:analytics.getUsersCount,
  },
}

export function* ADMIN_ANALYTICS({payload}) {
  yield put({
    type: 'analytics/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].tradeProcessingTime, payload)
  if (success.status === "success") {
    yield put({
      type: 'analytics/SET_STATE',
      payload: {
        data: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'analytics/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TRADE_ANALYTICS_WEEK({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].tradeSummary, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  weekTrade: success.data,
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TRADE_ANALYTICS_MONTH({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].tradeSummary, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  monthTrade: success.data,
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TRADE_ANALYTICS_YEAR({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].tradeSummary, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  yearTrade: success.data,
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* ADMIN_LOGOUT_LOG({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getLogOutLog, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  logoutLog: success.data,
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* ADMIN_LOGIN_LOG({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getLogOutLog, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loginLog: success.data,
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}


export function* PENDING_WITHDRAWALS_COUNT({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
		pendingWithdrawals:0
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getWithdrawalsCount, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			pendingWithdrawals: success.data.length > 0 && success.data[0].total,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TOTAL_TRADE_COUNT({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getTradesCount, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			totalTrade: success.data,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* PROCESSED_TRADE_COUNT({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getTradesCount, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			processedTrade: success.data,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* DECLINED_TRADE_COUNT({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getTradesCount, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			declinedTrade: success.data,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* PENDING_TRADE_COUNT({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getTradesCount, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			pendingTrade: success.data,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TOTAL_USERS_COUNT() {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getUsersCount)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			users: success.data.length > 0 && success.data[0].total,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TOTAL_ADMINS_COUNT() {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getAdminsCount)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			admins: success.data.length > 0 && success.data[0].total,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TOTAL_RATES_COUNT() {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getRatesCount)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			rates: success.data.length > 0 && success.data[0].total,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TOTAL_TICKETS_COUNT() {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getTicketsCount)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			tickets: success.data.length > 0 && success.data[0].total,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* UTILITIES_COUNT({payload}) {
	yield put({
	  type: 'analytics/SET_STATE',
	  payload: {
		loading: true,
		utilities:0
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].getUtilitiesCount, payload)
	if (success.status === "success") {
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
			utilities: success.data.length > 0 && success.data[0].total,
		  	loading:false
		},
	  })
	}else{
	  yield put({
		type: 'analytics/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADMIN_ANALYTICS, ADMIN_ANALYTICS),
    takeEvery(actions.TRADE_ANALYTICS_WEEK, TRADE_ANALYTICS_WEEK),
    takeEvery(actions.TRADE_ANALYTICS_MONTH, TRADE_ANALYTICS_MONTH),
    takeEvery(actions.TRADE_ANALYTICS_YEAR, TRADE_ANALYTICS_YEAR),
    takeEvery(actions.ADMIN_LOGOUT_LOG, ADMIN_LOGOUT_LOG),
    takeEvery(actions.ADMIN_LOGIN_LOG, ADMIN_LOGIN_LOG),

    takeEvery(actions.PENDING_WITHDRAWALS_COUNT, PENDING_WITHDRAWALS_COUNT),
    takeEvery(actions.TOTAL_TRADE_COUNT, TOTAL_TRADE_COUNT),
    takeEvery(actions.PROCESSED_TRADE_COUNT, PROCESSED_TRADE_COUNT),
    takeEvery(actions.DECLINED_TRADE_COUNT, DECLINED_TRADE_COUNT),
    takeEvery(actions.PENDING_TRADE_COUNT, PENDING_TRADE_COUNT),
    takeEvery(actions.TOTAL_USERS_COUNT, TOTAL_USERS_COUNT),
    takeEvery(actions.TOTAL_ADMINS_COUNT, TOTAL_ADMINS_COUNT),
    takeEvery(actions.TOTAL_RATES_COUNT, TOTAL_RATES_COUNT),
    takeEvery(actions.TOTAL_TICKETS_COUNT, TOTAL_TICKETS_COUNT),
    takeEvery(actions.UTILITIES_COUNT, UTILITIES_COUNT),
  ])
}
