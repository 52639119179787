import { all, put, call, select, takeEvery } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import * as notifications from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allNotifications: notifications.allNotifications,
    updateNotification: notifications.updateNotification,
  },
}

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export function* NOTIFICATIONS({payload}) {
  
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allNotifications, payload)
  if (success.status === "success") {
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        notifications: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_NOTIFICATION({payload}) {
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      loading: false,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateNotification, payload)
  if (success.status === "success") {
    yield put({
      type: 'menu/NOTIFICATIONS',
      payload: payload.adminId,
    })
  }
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.NOTIFICATIONS, NOTIFICATIONS),
    takeEvery(actions.UPDATE_NOTIFICATION, UPDATE_NOTIFICATION),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
