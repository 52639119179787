import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore } from 'index'
import * as loader from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allLoader: loader.allLoader,
    createLoader: loader.createLoader,
    updateLoader: loader.updateLoader,
    deleteLoader: loader.deleteLoader,
  },
}

export function* LOADER() {
  yield put({
    type: 'loader/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allLoader)
  if (success.status === "success") {
    yield put({
      type: 'loader/SET_STATE',
      payload: {
        loaders: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'loader/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_LOADER({payload}) {
  yield put({
    type: 'loader/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateLoader, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'loader/LOADER',
    })
    notification.success({
      message: 'Loader Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'loader/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* CREATE_LOADER({payload}) {
  yield put({
    type: 'loader/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createLoader, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'loader/LOADER',
    })
    notification.success({
      message: 'Loader Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'loader/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* DELETE_LOADER({payload}) {
  yield put({
    type: 'loader/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].deleteLoader, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'loader/LOADER',
    })
    notification.success({
      message: 'Loader deleted',
      description: success.message,
    })
  }else{
    yield put({
      type: 'loader/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOADER, LOADER),
    takeEvery(actions.CREATE_LOADER, CREATE_LOADER),
    takeEvery(actions.UPDATE_LOADER, UPDATE_LOADER),
    takeEvery(actions.DELETE_LOADER, DELETE_LOADER),
  ])
}
