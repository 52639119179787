/* eslint no-underscore-dangle: 0 */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history, store as reduxStore  } from 'index'
import * as admins from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    admins: admins.admins,
    allRemarks: admins.allRemarks,
    createRemark:admins.createRemark,
    roles: admins.roles,
    users: admins.users,
    searchUsers: admins.searchUsers,
    createAdmin: admins.createAdmin,
    createRole:admins.createRole,
    updateAdmin:admins.updateAdmin,
    updateWithdrawalRate:admins.updateWithdrawalRate,
    getWithdrawalRate:admins.getWithdrawalRate,
	setupProfile:admins.setupProfile,
    deleteAdminRole:admins.deleteAdminRole,
    updateAdminRole:admins.updateAdminRole,
    updateAdminStatus:admins.updateAdminStatus,
    activateAccount:admins.activateAccount,
    setPassword:admins.setPassword,
    updateUser:admins.updateUser,
    createUser:admins.createUser,
    singleUser:admins.singleUser,
    bankDetails:admins.bankDetails,
    enableOrDisable2Fa:admins.enableOrDisable2Fa,
    userTradeTransactions:admins.userTradeTransactions,
    userTransactions:admins.userTransactions,
    deleteRemark:admins.deleteRemark,
    resendVerificationMail: admins.resendVerificationMail,
  },
}

export function* ADMINS({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders.jwt.admins, payload)
  if (success.status === "success") {
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        admins: success.data.reverse(),
        loading:false
      },
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ROLES() {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].roles)
  if (success.status === "success") {
    // localStorage.setItem("roles", JSON.stringify(success));
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        roles: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REMARKS({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allRemarks, payload)
  if (success.status === "success") {
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        remarks: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE_REMARK({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createRemark, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/REMARKS',
      payload:"successfulRemarks"
    })
    notification.success({
      message: 'Remark Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* DELETE_REMARK({ payload }) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].deleteRemark, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/REMARKS',
      payload:payload.remarkType
    })
    notification.success({
      message: 'Remark Deleted',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* USERS({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].users, payload)
  if (success.status === "success") {
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        users: success.data,
        all: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SEARCH_USERS({payload}) {
	yield put({
	  type: 'admin/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].searchUsers, payload)
	if (success.status === "success") {
	  yield put({
		type: 'admin/SET_STATE',
		payload: {
		  users: success.data,
		  all: success.data,
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'admin/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}


export function* CREATE_ADMIN({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createAdmin, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/ADMINS',
    })
    notification.success({
      message: 'Admin created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'An error occured',
      description: success,
    })
  }
}

export function* UPDATE_USER({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateUser, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/USERS',
	  payload:`?skip=0&limit=30`
    })
    notification.success({
      message: 'User Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'An error occured',
      description: success,
    })
  }
}

export function* CREATE_USER({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createUser, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/USERS',
	  payload:`?skip=0&limit=30`
    })
    notification.success({
      message: 'User Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'An error occured',
      description: success,
    })
  }
}

export function* CREATE_ROLE({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createRole, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/ROLES',
    })
    notification.success({
      message: 'Role Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* SETUP_PROFILE({ payload }) {
	yield put({
	  type: 'admin/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	console.log(payload)
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].setupProfile, payload)
	if (success.status === "success") {
	  history.push('/auth/login')
	  yield put({
		type: 'admin/SET_STATE',
		payload: {
		  loading:false,
		},
	  })
	  notification.success({
		message: 'Profile Update',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'admin/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export function* UPDATE_ADMIN({ payload }) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateAdmin, payload)
  const data = JSON.parse(localStorage.getItem("data"))
  if (success.status === "success") {
    localStorage.setItem("data", JSON.stringify({
      firstName:success.data.firstName,
      lastName:success.data.lastName,
      email:success.data.email,
      phone:success.data.phone,
      adminType:success.data.adminType,
      deviceId:success.data.deviceId,
      photo:success.data.photo,
      password:success.data.password,
      id:success.data._id,
      role:success.data.role,
      token:data.token,
    }))
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading:false,
        avatar:success.data.photo
      },
    })
    notification.success({
      message: 'Profile Update',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* UPDATE_ADMIN_ROLE({ payload }) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateAdminRole, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/ADMINS',
    })
    notification.success({
      message: 'Role Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* UPDATE_ADMIN_STATUS({ payload }) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateAdminStatus, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/ADMINS',
    })
    notification.success({
      message: 'Admin Status Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* DELETE_ADMIN_ROLE({ payload }) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].deleteAdminRole, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/ADMINS',
    })
    notification.success({
      message: 'Admin Role Deleted',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* SET_PASSWORD({ payload }) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].setPassword, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'admin/ADMINS',
    })
    notification.success({
      message: 'Account Activated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* ACTIVATE_ACCOUNT({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].activateAccount, payload)
  if (success.status === "success") {
	localStorage.setItem("email", payload.email)
    yield history.push('/auth/register')
    notification.success({
      message: 'Account Activation',
      description: success.message,
    })
  }else{
    notification.success({
      message: 'An error occured',
      description: success,
    })
  }
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SINGLE_USERS({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      singleUser:{},
      loading: true
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].singleUser, payload)
  if (success.status === "success") {
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        singleUser: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* BANK_DETAILS({payload}) {
	yield put({
	  type: 'admin/SET_STATE',
	  payload: {
		bankData:{},
		loading: true
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].bankDetails, payload)
	if (success.status === "success") {
	  yield put({
		type: 'admin/SET_STATE',
		payload: {
		  bankData: success.data,
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'admin/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* USER_TRANSACTIONS({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].userTradeTransactions, payload)
  if (success.status === "success") {
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        transactions: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* WALLET_TRANSACTIONS({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].userTransactions, payload)
  if (success.status === "success" && success.data !== null) {
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        walletTransactions: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'admin/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ENABLE_OR_DISABLE_2FA({payload}) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].enableOrDisable2Fa, payload)
  if (success.status === "success") {
	yield reduxStore.dispatch({
		type: 'admin/SINGLE_USERS',
        payload:payload.id
	  })
      notification.success({
		message: '2factor authentication updated',
		description: success.message,
	})
  }else{
	yield put({
		type: 'admin/SINGLE_USERS',
		payload:payload.id
	})
    notification.success({
		message: 'An error occured',
		description: success,
	})
  }
}

export function* RESEND_VERIFICATION_MAIL({ payload }) {
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].resendVerificationMail, payload)
  if (success.status === "success") {
    notification.success({
      message: 'Email Verification Resend',
      description: success.message,
    })
  }else{
    notification.success({
      message: 'error occur',
      description: success.message,
    })
  }
  yield put({
    type: 'admin/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SET_NOTIFICATION({ payload }) {
  notification.success({
    message: 'Please wait',
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateAdmin, payload)
  const data = JSON.parse(localStorage.getItem("data"))
  if (success.status === "success") {
    localStorage.setItem("data", JSON.stringify({
      firstName:success.data.firstName,
      lastName:success.data.lastName,
      email:success.data.email,
      phone:success.data.phone,
      adminType:success.data.adminType,
      deviceId:success.data.deviceId,
      photo:success.data.photo,
      password:success.data.password,
      id:success.data._id,
      role:success.data.role,
      token:data.token,
    }))
    if(success.data.deviceId === ""){
      localStorage.removeItem("deviceId");
    }
    notification.success({
      message: 'Notification Update',
      description: success.message,
    })
  }else{
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DELETE_REMARK, DELETE_REMARK),
    takeEvery(actions.DELETE_ADMIN_ROLE, DELETE_ADMIN_ROLE),
    takeEvery(actions.UPDATE_ADMIN_ROLE, UPDATE_ADMIN_ROLE),
    takeEvery(actions.UPDATE_ADMIN, UPDATE_ADMIN),
    takeEvery(actions.UPDATE_ADMIN_STATUS, UPDATE_ADMIN_STATUS),
    takeEvery(actions.CREATE_ADMIN, CREATE_ADMIN),
    takeEvery(actions.CREATE_ROLE, CREATE_ROLE),
    takeEvery(actions.SET_PASSWORD, SET_PASSWORD),
    takeEvery(actions.ACTIVATE_ACCOUNT, ACTIVATE_ACCOUNT),
    takeEvery(actions.ADMINS, ADMINS),
    takeEvery(actions.ROLES, ROLES),
    takeEvery(actions.REMARKS, REMARKS),
    takeEvery(actions.CREATE_REMARK, CREATE_REMARK),
    takeEvery(actions.USERS, USERS),
    takeEvery(actions.SEARCH_USERS, SEARCH_USERS),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.CREATE_USER, CREATE_USER),
    takeEvery(actions.SINGLE_USERS, SINGLE_USERS),
    takeEvery(actions.ENABLE_OR_DISABLE_2FA, ENABLE_OR_DISABLE_2FA),
    takeEvery(actions.USER_TRANSACTIONS, USER_TRANSACTIONS),
    takeEvery(actions.WALLET_TRANSACTIONS, WALLET_TRANSACTIONS),
    takeEvery(actions.RESEND_VERIFICATION_MAIL, RESEND_VERIFICATION_MAIL),
    takeEvery(actions.SET_NOTIFICATION, SET_NOTIFICATION),
    takeEvery(actions.SETUP_PROFILE, SETUP_PROFILE),
    takeEvery(actions.BANK_DETAILS, BANK_DETAILS),

  ])
}
