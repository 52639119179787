import actions from './actions'

const initialState = {
  withdrawalSettings:{},
  loading:false,
  isUpdated: false
}

export default function WithdrawalSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
