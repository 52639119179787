import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as message from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    sendBroadcast: message.sendBroadcast,
  },
}

export function* SEND_BROADCAST({payload}) {
  yield put({
    type: 'message/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].sendBroadcast, payload)
  if (success.status === "success") {
    notification.success({
      message: 'Broadcast sent',
      description: success.message,
    })
  }else{
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
  yield put({
	type: 'message/SET_STATE',
	payload: {
	  loading: false,
	},
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_BROADCAST, SEND_BROADCAST),
  ])
}
