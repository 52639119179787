import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { getBitPay } from 'services/jwt'
// import { notification } from 'antd'
// import { store as reduxStore } from 'index'
import * as utilities from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allUsersTransactions: utilities.allUsersTransactions,
    searchTransactions: utilities.searchTransactions,
  },
}

export function* UTILITIES_TRANSACTIONS({payload}) {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
	  data:[],
	  all:[],
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allUsersTransactions, payload)
  if (success.status === "success" && success.data !== null) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        data: success.data.reverse(),
        all: success.data.reverse(),
        loading:false
      },
    })
  }else{
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SEARCH_TRANSACTIONS({payload}) {
	yield put({
	  type: 'utilities/SET_STATE',
	  payload: {
		data:[],
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].searchTransactions, payload)
	if (success.status === "success" && success.data !== null) {
	  yield put({
		type: 'utilities/SET_STATE',
		payload: {
		  data: success.data.reverse(),
		  loading:false
		},
	  })
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  transactions: success.data.reverse(),
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'utilities/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* GET_EXPORTS({payload}) {
	yield put({
	  type: 'utilities/SET_STATE',
	  payload: {
		fetching: true,
		exports:[]
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].allUsersTransactions, payload)
	if (success.status === "success" && success.data !== null) {
	  yield put({
		type: 'utilities/SET_STATE',
		payload: {
		  exports: success.data,
		  fetching:false
		},
	  })
	}else{
	  yield put({
		type: 'utilities/SET_STATE',
		payload: {
		  fetching: true,
		},
	  })
	}
}

export function* GET_BITPAY() {
	yield put({
	  type: 'utilities/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const success = yield call(getBitPay)
	if (success && success.length >0) {
	  yield put({
		type: 'utilities/SET_STATE',
		payload: {
		  rates: success
		},
	  })
	}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UTILITIES_TRANSACTIONS, UTILITIES_TRANSACTIONS),
    takeEvery(actions.SEARCH_TRANSACTIONS, SEARCH_TRANSACTIONS),
    takeEvery(actions.GET_EXPORTS, GET_EXPORTS),
    takeEvery(actions.GET_BITPAY, GET_BITPAY),
  ])
}
