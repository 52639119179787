import React, {useEffect} from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
import Footer from 'components/cleanui/layout/Footer'
import Sidebar from 'components/cleanui/layout/Sidebar'
// import SupportChat from 'components/cleanui/layout/SupportChat'

const mapStateToProps = ({ settings, dispatch, chat, trade, withdrawal }) => ({
  dispatch,
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  pusher: chat.pusher,
  newTrades: trade.newTrades,
  newWithdrawals: withdrawal.newWithdrawals,
  newSession: chat.newSession,
})

const MainLayout = ({
  dispatch,
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  pusher,
  newTrades,
  newWithdrawals,
  newSession
}) => {
	if(pusher !== null){
		const tradeChannel = pusher.subscribe('public-zabiraTrades');
		const withdrawalChannel = pusher.subscribe('public-zabiraWalletUpdate-30480957');
		const chatChannel = pusher.subscribe('public-sessionCreation-30480957');

		// listening to new trades
		tradeChannel.bind('newTradeAlert', (data) => {
			const newTrade = newTrades
			newTrade.push(data.tradeId)
			dispatch({
				type: 'trade/TRADES',
				payload:`skip=0&limit=30&status=pending`
			})
			const userData = JSON.parse(localStorage.getItem("data"));
			dispatch({
				type: 'menu/NOTIFICATIONS',
				payload:userData.id
			})
			dispatch({
				type: 'trade/SET_STATE',
				payload:{
					newTrades:newTrade
				}
			})
		})

		// listening to new withdrawal requests
		withdrawalChannel.bind('userCredit', (data) => {
			const credit = newWithdrawals
			credit.push(data.userId)
			dispatch({
				type: 'withdrawal/WITHDRAWALS',
				payload:`skip=0&limit=30&status=pending`
			})
			dispatch({
				type: 'withdrawal/SET_STATE',
				payload:{
					newWithdrawals:credit
				}
			})
		})

		// listening to new chat channel
		chatChannel.bind('sessionCreated', (data) => {
			const session = newSession
			session.push(data.sessionId)
			dispatch({
				type: 'chat/CHATS',
			})
			dispatch({
				type: 'chat/SET_STATE',
				payload:{
					newSession:session
				}
			})
		})
	}
	useEffect(() => {
		dispatch({
			type: 'chat/PUSHER_INSTANCE',
		})
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [])

  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        <Sidebar />
        {/* <SupportChat /> */}
        <Menu />
        <Layout>
          <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>
          <Breadcrumbs />
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="cui__utils__content">{children}</div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
