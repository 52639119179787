import actions from './actions'

const initialState = {
  tickets: [],
  remarks: [],
  categories:[],
  analytics:[],
  exports:[],
  trades:[],
  transactions:[],
  newTrades:[],
  all:[],
  allAnalytics:[],
  singleTrade:{},
  singleTradeAnalytics:{},
  loading:false,
  isLoading:false,
  isToggled:false
}

export default function tradeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
