import React from 'react'
import { connect } from 'react-redux'
import { Dropdown, Badge } from 'antd'
import List2 from 'components/kit/widgets/Lists/2'
import styles from './style.module.scss'

const mapStateToProps = ({ menu }) => ({
  notifications:menu.notifications,
})
const Actions = ({notifications}) => {
  const counter = notifications.filter(note=>note.status === "unread" && note.body && (note.body.status !== "declined" || note.body.status !== "successful" || note.body.status !== "active")).reverse();
  const menu = (
    <React.Fragment>
      <div className="card cui__utils__shadow width-350 border-0">
        <div className="card-body p-0">
          <List2 notifications={counter} />
        </div>
      </div>
    </React.Fragment>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <Badge count={counter.length}>
          <i className={`${styles.icon} fe fe-bell`} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(Actions)
