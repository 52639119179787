
const serverVars = {
  baseUrl: "https://server.zabira.ng/api/v1/",
 // baseUrl: "https://server.zabira.ng/api/v1/",
// baseUrl: "http://127.0.0.1:7000/api/v1/",
//   baseUrl: "https://zabira.ddns.net/api/v1/",
//   baseUrl:"http://ec2-34-211-190-192.us-west-2.compute.amazonaws.com:7000/api/v1/",
  adminlogin:"admin/login",
  loginLog:"metrics/login-log",
  logoutLog:"metrics/log-logout",
  allAdmins:"admin/all-admins",
  forgotPassword:"admin/forgot-password",
  resetPassword:"admin/reset-password",
  allRoles:"admin/get-admin-roles",
  createAdmin:"admin/create-admin",
  updateAdmin:"admin/update-admin-info?email=",
  setupProfile:"admin/update-admin-info?email=",
  updateAdminRole:"admin/update-admin-priviledge",
  deleteAdminRole:"admin/delete-admin-role",
  createRole:"admin/add-admin-role",
  setPassword:"admin/set-password",
  activateAccount:"admin/activate-account",
  updateAdminStatus:"admin/",
  resendVerificationMail:"admin/send-admin-verification-mail",
  resendActivationCode:"admin/send-activation-code",

  allTickets:"ticket/all-tickets",
  allCategory:"ticket/ticket-category",
  createTicket:"ticket/create-ticket",
  updateTicket:"ticket/update-ticket-status?ticketId=",
  createCategory:"ticket/create-ticket-category",

  createTrade:"trade/create-trade",
  createAgentTrade:"trade/create-agent-trade",
  updateTradeStatus:"trade/update-trade-status?tradeId=",
  updateAdminTrade:"trade/update-admin-trade?tradeId=",
  takeUpTrade:"trade/take-up-trade?tradeId=",
  rateTrade:"rating?tradeId=",
  allTrade:"trade",
  userTradeTransactions:"trade/trade-by-user",
  updateAttention:"trade/update-user-trade?tradeId=",

  allRate:"rate",
  createRate:"rate/create-rate",
  updateRate:"rate/batch-update",
  disableRate:"rate/update-rate/",
  deleteRate:"rate/delete-rate/",
  allGiftCard:"rate/all-gift-cards",
  allCard:"rate/cards",
  createGiftCard:"rate/create-card",
  updateGiftCard:"rate/update-card",
  singleGiftCard:"rate/all-gift-cards?giftCard=",
  allCountries:"rate/all-countries",
  updateStartWindow:"rate/update-card-window",
  allUsers:"user/",
  searchUsers:"user/search-user",
  exportUsers:"user/export",
  updateUser:"user/update-profile/",
  createUser:"auth/signUp",
  enableOrDisable2Fa:"auth/",
  allNotifications:"notification?&userId=",
  updateNotification:"notification/set-notification-status?notificationId=",
  userTransactions:"wallet/get-account-statement",
  searchTransactions:"wallet/search-account-statement?searchWord=", // locked&withdrawalRequest=true
  searchTradeTransactions:"trade/search-trade?searchWord=",
  exportTransactions:"utility/export",
  exportTrades:"trade/export",
  exportWithdrawals:"wallet/export",

  singleRemark:"admin/remarks?remarkType=",
  allRemarks:"admin/all-remarks",
  createRemark:"admin/remarks",

  createBtcTrade:"btc/create-btc-rate",
  allBtcTrades:"btc/get-btc-rates",
  deleteBtcTrade:"btc/delete-btc-rate?rateId=",
  updateBtcTrade:"btc/update-btc-rate?rateId=",

  // allLoader,
  loader:"loader",
  deleteLoader:"loader?loaderId=",
  updateLoader:"loader?loaderId=",

  tradeAnalytics:"analytics",
  downloadAnalytics:"analytics?isCsv=true",

  sendBroadcast:"notification/broadcast-push-notification",
  sendBroadcastGroup:"notification/broadcast-to-user-group",
  // banner
  banner:"banner",

  //  chats
  allChannels:"pusher/sessions",
  takeUpSession:"pusher/take-up-session",
  sendMessage:"pusher/message",
  closeSession:"pusher/end-session",

  allWithdrawals:"wallet/withdrawal-requests",
  takeUpWithdrawal:"wallet/take-up-withdrawal-request",
  updateWithdrawal:"wallet/update-withdrawal-request",
  revertWithdrawal:"wallet/revert-withdrawal",
  bankDetails:"wallet/get-user-bank-details?userId=",

  tradeProcessingTime:"metrics/get-trade-processing-time",
  getLog :"metrics/get-log?type=",
  tradeSummary:"metrics/trade-metrics",

  getUtilitiesCount:"metrics/dashboard-metrics-utility",
  getWithdrawalsCount:"metrics/dashboard-metrics-withdrawal",
  getTradesCount:"metrics/dashboard-metrics-trades",
  getTicketsCount:"metrics/dashboard-metrics-tickets",
  getRatesCount:"metrics/dashboard-metrics-cards",
  getAdminsCount:"metrics/dashboard-metrics-admins",
  getUsersCount:"metrics/dashboard-metrics-users",

  getAdminDevices:"admin/get-devices",
  updateAdminDevice:"admin/status-allowed-devices?adminId=",

  updateWithdrawalRate:"withdrawal-rate/update",
  getWithdrawalRate:"withdrawal-rate",

// ?status=closed&userId=5ffc74a33c388701bcbcb583&skip=2&adminId=5fb94f2a1aecef1adae508ca
};
// ?limit=3&skip=1&trade=60070fa74a717f04d9b35e1e&downloadCsv=true
// ?searchWord=declined&limit=1&skip=1

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(value);

  export const formatBitcoin = (value) => {
	const nf = new Intl.NumberFormat("en-US", {
	  minimumFractionDigits: 8,
	  maximumFractionDigits: 8,
	});
	return nf.format(value);
  };

  export const convertBtcToDollar =(value, rates) =>{
	  const btc = formatBitcoin(value)
	  const usdRate = rates.find(r=> r.code === "USD")
	  let val = ""
	  if(usdRate){
		val = btc * usdRate.rate
		return val.toFixed(2);
	  }
	  return val
  }

export function getConfig(apiName) {
  const data = JSON.parse(localStorage.getItem("data"))
  switch (apiName) {
    case "adminlogin":
        return serverVars.baseUrl + serverVars.adminlogin;
	case "loginLog":
	    return serverVars.baseUrl + serverVars.loginLog;
	case "logoutLog":
		return serverVars.baseUrl + serverVars.logoutLog;
    case "allAdmins":
        return serverVars.baseUrl + serverVars.allAdmins;
    case "forgotPassword":
        return serverVars.baseUrl + serverVars.forgotPassword;
    case "resetPassword":
        return serverVars.baseUrl + serverVars.resetPassword;
    case "allRoles":
        return serverVars.baseUrl + serverVars.allRoles;
    case "createAdmin":
        return serverVars.baseUrl + serverVars.createAdmin;
    case "updateAdmin":
        return serverVars.baseUrl + serverVars.updateAdmin + data.email;
    case "updateWithdrawalRate":
      return serverVars.baseUrl + serverVars.updateWithdrawalRate;
    case "getWithdrawalRate":
      return serverVars.baseUrl + serverVars.getWithdrawalRate;
	case "setupProfile":
		return serverVars.baseUrl + serverVars.setupProfile;
    case "updateAdminRole":
        return serverVars.baseUrl + serverVars.updateAdminRole;
    case "createRole":
        return serverVars.baseUrl + serverVars.createRole;
    case "setPassword":
        return serverVars.baseUrl + serverVars.setPassword;
    case "activateAccount":
        return serverVars.baseUrl + serverVars.activateAccount;
    case "updateAdminStatus":
        return serverVars.baseUrl + serverVars.updateAdminStatus;
    case "allTickets":
        return serverVars.baseUrl + serverVars.allTickets;
    case "allCategory":
        return serverVars.baseUrl + serverVars.allCategory;
    case "createTicket":
        return serverVars.baseUrl + serverVars.createTicket;
    case "updateTicket":
        return serverVars.baseUrl + serverVars.updateTicket;
    case "createCategory":
        return serverVars.baseUrl + serverVars.createCategory;

    case "createTrade":
        return serverVars.baseUrl + serverVars.createTrade;
    case "createAgentTrade":
        return serverVars.baseUrl + serverVars.createAgentTrade;
    case "updateTradeStatus":
        return serverVars.baseUrl + serverVars.updateTradeStatus;
    case "updateAdminTrade":
        return serverVars.baseUrl + serverVars.updateAdminTrade;
    case "takeUpTrade":
        return serverVars.baseUrl + serverVars.takeUpTrade;
    case "rateTrade":
        return serverVars.baseUrl + serverVars.rateTrade;
    case "allTrade":
        return serverVars.baseUrl + serverVars.allTrade;
    case "updateAttention":
        return serverVars.baseUrl + serverVars.updateAttention;
    case "allRate":
        return serverVars.baseUrl + serverVars.allRate;
    case "createRate":
        return serverVars.baseUrl + serverVars.createRate;
    case "updateRate":
        return serverVars.baseUrl + serverVars.updateRate;
	case "disableRate":
		return serverVars.baseUrl + serverVars.disableRate;
    case "deleteRate":
        return serverVars.baseUrl + serverVars.deleteRate;
    case "allGiftCard":
        return serverVars.baseUrl + serverVars.allGiftCard;
    case "singleGiftCard":
        return serverVars.baseUrl + serverVars.singleGiftCard;
    case "allCountries":
        return serverVars.baseUrl + serverVars.allCountries;
	case "updateStartWindow":
		return serverVars.baseUrl + serverVars.updateStartWindow;
    case "allUsers":
        return serverVars.baseUrl + serverVars.allUsers;
	case "searchUsers":
		return serverVars.baseUrl + serverVars.searchUsers;
	case "exportUsers":
		return serverVars.baseUrl + serverVars.exportUsers;
    case "updateUser":
        return serverVars.baseUrl + serverVars.updateUser;
    case "createUser":
        return serverVars.baseUrl + serverVars.createUser;
    case "allRemarks":
        return serverVars.baseUrl + serverVars.allRemarks;
    case "singleRemark":
        return serverVars.baseUrl + serverVars.singleRemark;
    case "createRemark":
        return serverVars.baseUrl + serverVars.createRemark;
    case "enableOrDisable2Fa":
        return serverVars.baseUrl + serverVars.enableOrDisable2Fa;
    case "userTradeTransactions":
        return serverVars.baseUrl + serverVars.userTradeTransactions;
    case "allNotifications":
        return serverVars.baseUrl + serverVars.allNotifications;
    case "updateNotification":
        return serverVars.baseUrl + serverVars.updateNotification;
    case "userTransactions":
        return serverVars.baseUrl + serverVars.userTransactions;
	case "searchTradeTransactions":
		return serverVars.baseUrl + serverVars.searchTradeTransactions;
	case "searchTransactions":
		return serverVars.baseUrl + serverVars.searchTransactions;
	case "exportTransactions":
		return serverVars.baseUrl + serverVars.exportTransactions;
	case "exportTrades":
		return serverVars.baseUrl + serverVars.exportTrades;
	case "exportWithdrawals":
		return serverVars.baseUrl + serverVars.exportWithdrawals;
    case "deleteAdminRole":
        return serverVars.baseUrl + serverVars.deleteAdminRole;
    case "allBtcTrades":
        return serverVars.baseUrl + serverVars.allBtcTrades;
    case "createBtcTrade":
        return serverVars.baseUrl + serverVars.createBtcTrade;
    case "deleteBtcTrade":
        return serverVars.baseUrl + serverVars.deleteBtcTrade;
    case "updateBtcTrade":
        return serverVars.baseUrl + serverVars.updateBtcTrade;
        case "loader":
        return serverVars.baseUrl + serverVars.loader;
    case "createLoader":
        return serverVars.baseUrl + serverVars.createLoader;
    case "deleteLoader":
        return serverVars.baseUrl + serverVars.deleteLoader;
    case "updateLoader":
        return serverVars.baseUrl + serverVars.updateLoader;
    case "resendVerificationMail":
        return serverVars.baseUrl + serverVars.resendVerificationMail;
    case "resendActivationCode":
        return serverVars.baseUrl + serverVars.resendActivationCode;
    case "tradeAnalytics":
        return serverVars.baseUrl + serverVars.tradeAnalytics;
    case "downloadAnalytics":
        return serverVars.baseUrl + serverVars.downloadAnalytics;
	case "sendBroadcast":
		return serverVars.baseUrl + serverVars.sendBroadcast;
	case "sendBroadcastGroup":
		return serverVars.baseUrl + serverVars.sendBroadcastGroup;
	case "banner":
		return serverVars.baseUrl + serverVars.banner;
	case "allChannels":
		return serverVars.baseUrl + serverVars.allChannels;
	case "takeUpSession":
		return serverVars.baseUrl + serverVars.takeUpSession;
	case "sendMessage":
		return serverVars.baseUrl + serverVars.sendMessage;
	case "closeSession":
		return serverVars.baseUrl + serverVars.closeSession;
	case "allWithdrawals":
		return serverVars.baseUrl + serverVars.allWithdrawals;
	case "takeUpWithdrawal":
		return serverVars.baseUrl + serverVars.takeUpWithdrawal;
	case "updateWithdrawal":
		return serverVars.baseUrl + serverVars.updateWithdrawal;
	case "revertWithdrawal":
		return serverVars.baseUrl + serverVars.revertWithdrawal;
	case "bankDetails":
		return serverVars.baseUrl + serverVars.bankDetails;
	case "allCard":
		return serverVars.baseUrl + serverVars.allCard;
    case "createGiftCard":
        return serverVars.baseUrl + serverVars.createGiftCard;
    case "updateGiftCard":
        return serverVars.baseUrl + serverVars.updateGiftCard;
	case "getLog":
		return serverVars.baseUrl + serverVars.getLog;
	case "tradeSummary":
		return serverVars.baseUrl + serverVars.tradeSummary;
	case "tradeProcessingTime":
		return serverVars.baseUrl + serverVars.tradeProcessingTime;
	case "getAdminDevices":
		return serverVars.baseUrl + serverVars.getAdminDevices;
	case "updateAdminDevice":
		return serverVars.baseUrl + serverVars.updateAdminDevice;

	case "getUtilitiesCount":
		return serverVars.baseUrl + serverVars.getUtilitiesCount;
	case "getWithdrawalsCount":
		return serverVars.baseUrl + serverVars.getWithdrawalsCount;
	case "getTradesCount":
		return serverVars.baseUrl + serverVars.getTradesCount;
	case "getTicketsCount":
		return serverVars.baseUrl + serverVars.getTicketsCount;
	case "getRatesCount":
		return serverVars.baseUrl + serverVars.getRatesCount;
	case "getAdminsCount":
		return serverVars.baseUrl + serverVars.getAdminsCount;
	case "getUsersCount":
		return serverVars.baseUrl + serverVars.getUsersCount;
    default:
      return null;
  }
}
