import actions from './actions'

const initialState = {
  chats: [],
  chatMessage:[],
  newSession:[],
  ongoing:false,
  all:[],
  loading:false,
  pusher:null,
  channel:null,
  userData:null
}

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
