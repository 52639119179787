import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history, store as reduxStore  } from 'index'
import * as withdrawal from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allWithdrawals: withdrawal.allWithdrawals,
    singleWithdraw: withdrawal.singleWithdraw,
    takeUpWithdrawal: withdrawal.takeUpWithdrawal,
	updateWithdrawal: withdrawal.updateWithdrawal,
	revertWithdrawal: withdrawal.revertWithdrawal
  },
}

// fetch all withdrawals
export function* WITHDRAWALS({payload}) {
  yield put({
    type: 'withdrawal/SET_STATE',
    payload: {
      loading: true,
	  singleWithdraw:{},
	  withdrawals:[]
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allWithdrawals, payload)
  if (success.status === "success") {
    yield put({
      type: 'withdrawal/SET_STATE',
      payload: {
        withdrawals: success.data.reverse(),
        all: success.data.reverse(),
        loading:false
      },
    })
  }else{
    yield put({
      type: 'withdrawal/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TRANSACTIONS({payload}) {
	yield put({
	  type: 'withdrawal/SET_STATE',
	  payload: {
		isLoading: true,
		transactions:[]
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].allWithdrawals, payload)
	if (success.status === "success") {
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  transactions: success.data.reverse(),
		  isLoading:false
		},
	  })
	}else{
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  isLoading: false,
		},
	  })
	}
}

export function* SINGLE_WITHDRAWAL({payload}) {
	yield put({
	  type: 'withdrawal/SET_STATE',
	  payload: {
		singleWithdraw:{},
		loading: true
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].singleWithdraw, payload)
	if (success.status === "success") {
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  singleWithdraw: success.data[0],
		  loading:false
		},
	  })
	}else{
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	}
}

export function* TAKE_UP_WITHDRAWAL({payload}) {
	yield put({
	  type: 'withdrawal/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].takeUpWithdrawal, payload)
	if (success.status === "success") {
	  yield reduxStore.dispatch({
		type: 'withdrawal/WITHDRAWALS',
		payload:`skip=0&limit=30&status=pending`
	  })
	  notification.success({
		message: 'Withdrawal Taken',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export function* UPDATE_WITHDRAWAL({payload}) {
	yield put({
	  type: 'withdrawal/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].updateWithdrawal, payload)
	if (success.status === "success") {
		history.push('/withdrawal-request')
	//   yield reduxStore.dispatch({
	// 	type: 'withdrawal/SINGLE_WITHDRAWAL',
	// 	payload:payload.id
	//   })
	  notification.success({
		message: 'Withdrawal Updated',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export function* REVERT_WITHDRAWAL({payload}) {
	yield put({
	  type: 'withdrawal/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].revertWithdrawal, payload)
	if (success.status === "success") {
		history.push('/withdrawal-request')
	//   yield reduxStore.dispatch({
	// 	type: 'withdrawal/SINGLE_WITHDRAWAL',
	// 	payload:payload.id
	//   })
	  notification.success({
		message: 'Withdrawal Updated',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export function* GET_EXPORTS({payload}) {
	yield put({
	  type: 'withdrawal/SET_STATE',
	  payload: {
		fetching: true,
		exports:[]
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].allWithdrawals, payload)
	if (success.status === "success" && success.data !== null) {
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  exports: success.data,
		  fetching:false
		},
	  })
	}else{
	  yield put({
		type: 'withdrawal/SET_STATE',
		payload: {
		  fetching: true,
		},
	  })
	}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.WITHDRAWALS, WITHDRAWALS),
    takeEvery(actions.TRANSACTIONS, TRANSACTIONS),
    takeEvery(actions.SINGLE_WITHDRAWAL, SINGLE_WITHDRAWAL),
    takeEvery(actions.TAKE_UP_WITHDRAWAL, TAKE_UP_WITHDRAWAL),
    takeEvery(actions.UPDATE_WITHDRAWAL, UPDATE_WITHDRAWAL),
    takeEvery(actions.REVERT_WITHDRAWAL, REVERT_WITHDRAWAL),
    takeEvery(actions.GET_EXPORTS, GET_EXPORTS),
  ])
}
