import actions from './actions'

const initialState = {
  withdrawals: [],
  transactions: [],
  all: [],
  newWithdrawals: [],
  exports: [],
  singleWithdraw:{},
  loading:false,
  isLoading:false,
  fetching:false,
}

export default function withdrawalReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
