import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore, history  } from 'index'
import * as trade from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allTickets: trade.allTickets,
    allCategory: trade.allCategory,
    createTicket: trade.createTicket,
    updateTicket: trade.updateTicket,
    createCategory: trade.createCategory,

    allTrade: trade.allTrade,
    searchTradeTransactions: trade.searchTradeTransactions,
    createTrade: trade.createTrade,
    createAgentTrade: trade.createAgentTrade,
    updateTradeStatus: trade.updateTradeStatus,
    // updateAttention: trade.updateAttention,
    updateAdminTrade: trade.updateAdminTrade,
    takeUpTrade: trade.takeUpTrade,
    rateTrade: trade.rateTrade,
    singleTrade: trade.singleTrade,
    allRemarks: trade.allRemarks,
    tradeAnalytics: trade.tradeAnalytics,
    singleTradeAnalytics: trade.singleTradeAnalytics,
    downloadCsv:trade.downloadCsv
  },
}

export function* TICKETS() {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allTickets)
  if (success.status === "success") {
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        tickets: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TICKETS_CATEGORY() {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allCategory)
  if (success.status === "success") {
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        categories: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE_TICKET({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createTicket, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TICKETS',
	  payload:`skip=0&limit=30`
    })
    notification.success({
      message: 'Ticket Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* CREATE_CATEGORY({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createCategory, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TICKETS_CATEGORY',
    })
    notification.success({
      message: 'Ticket Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* UPDATE_TICKET({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateTicket, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TICKETS',
	  payload:`skip=0&limit=30`
    })
    notification.success({
      message: 'Ticket Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* SINGLE_TRADE({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      singleTrade:{},
      isLoading: true
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].singleTrade, payload)
  if (success.status === "success") {
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        singleTrade: success.data,
        isLoading:false
      },
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        isLoading: false,
      },
    })
  }
}

export function* TRADES({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
      singleTrade:{},
	  trades:[],
	  all:[]
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allTrade, payload)
  if (success.status === "success") {
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        trades: success.data,
        all: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TRANSACTIONS({payload}) {
	yield put({
	  type: 'trade/SET_STATE',
	  payload: {
      transactions:[],
		  isLoading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].allTrade, payload)
	if (success.status === "success") {
	  yield put({
		type: 'trade/SET_STATE',
		payload: {
		  transactions: success.data,
		  isLoading:false
		},
	  })
	}else{
	  yield put({
		type: 'trade/SET_STATE',
		payload: {
		  isLoading: false,
		},
	  })
	}
}

export function* GET_EXPORTS({payload}) {
	yield put({
	  type: 'trade/SET_STATE',
	  payload: {
		exports:[],
		fetching: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].allTrade, payload)
	if (success.status === "success") {
	  yield put({
		type: 'trade/SET_STATE',
		payload: {
		  exports: success.data,
		  fetching:false
		},
	  })
	}else{
	  yield put({
		type: 'trade/SET_STATE',
		payload: {
		  fetching: true,
		},
	  })
	}
}

export function* SEARCH_TRANSACTIONS({payload}) {
	yield put({
	  type: 'trade/SET_STATE',
	  payload: {
      transactions:[],
      isLoading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].searchTradeTransactions, payload)
	if (success.status === "success" && success.data !== null) {
	  yield put({
		type: 'trade/SET_STATE',
		payload: {
			transactions: success.data.reverse(),
		  	isLoading:false
		},
	  })
	}else{
	  yield put({
		type: 'trade/SET_STATE',
		payload: {
		  isLoading: false,
		},
	  })
	}
}

export function* REMARKS({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allRemarks, payload)
  if (success.status === "success") {
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        remarks: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE_AGENT_TRADE({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createAgentTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TRADES',
	  payload:`skip=0&limit=30&status=pending`
    })
    notification.success({
      message: 'Agent Trade Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* CREATE_TRADE({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TRADES',
	  payload:`skip=0&limit=30&status=pending`
    })
    notification.success({
      message: 'Trade Created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* UPDATE_TRADE_STATUS({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateTradeStatus, payload)
  console.log(success)
  if (success.status === "success") {
    yield history.push('/trade')
    notification.success({
      message: 'Trade Status Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* CONTINUE_TRADE_STATUS({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateTradeStatus, payload)
  if (success.status === "success") {
    yield history.push('/trade')
    notification.success({
      message: 'Trade Status Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* ATTENTION_TRADE_STATUS({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateTradeStatus, payload)
  if (success.status === "success") {
    yield history.push('/trade')
    notification.success({
      message: 'Trade Status Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}


export function* UPDATE_ADMIN_TRADE({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateAdminTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TRADES',
	  payload:`skip=0&limit=30&status=pending`
    })
    notification.success({
      message: 'Admin Trade Updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* TAKE_UP_TRADE({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].takeUpTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TRADES',
	  payload:`skip=0&limit=30&status=pending`
    })
    notification.success({
      message: 'Trade Taken',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* RATE_TRADE({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].rateTrade, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'trade/TRADES',
	  payload:`skip=0&limit=30&status=pending`
    })
    notification.success({
      message: 'Trade Rate',
      description: success.message,
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* CHANGE_TOGGLE() {
  const { isToggled: isToggledName } = yield select(state => state.trade)
  const { trades: tradesData } = yield select(state => state.trade)
  yield put({
      type: 'trade/SET_STATE',
      payload: {
        isToggled: !isToggledName,
        trades:tradesData.reverse()
      },
    })
}

export function* SINGLE_TRADE_ANALYTICS({payload}) {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      singleTradeAnalytics:{},
      loading: true
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].singleTradeAnalytics, payload)
  if (success.status === "success") {
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        singleTradeAnalytics: success.data[0],
        loading:false
      },
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TRADE_ANALYTICS({payload}) {

  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].tradeAnalytics, payload)
  if (success.status === "success") {
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        analytics: success.data,
        allAnalytics:success.data,
        fetching: true,
      },
    })
  }else{
    yield put({
      type: 'trade/SET_STATE',
      payload: {
        fetching: true,
      },
    })
  }
}

export function* DOWNLOAD_TRADE_ANALYTICS() {
  yield put({
    type: 'trade/SET_STATE',
    payload: {
      loading: true
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].downloadCsv)
  console.log(success)
  if (success.status === "success") {
    notification.success({
      message: 'File Downloaded',
      description: success.message,
    })
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.TICKETS, TICKETS),
    takeEvery(actions.CREATE_CATEGORY, CREATE_CATEGORY),
    takeEvery(actions.CREATE_TICKET, CREATE_TICKET),
    takeEvery(actions.UPDATE_TICKET, UPDATE_TICKET),
    takeEvery(actions.TICKETS_CATEGORY, TICKETS_CATEGORY),

    takeEvery(actions.GET_EXPORTS, GET_EXPORTS),
    takeEvery(actions.TRADES, TRADES),
    takeEvery(actions.TRANSACTIONS, TRANSACTIONS),
    takeEvery(actions.SINGLE_TRADE, SINGLE_TRADE),
    takeEvery(actions.CREATE_AGENT_TRADE, CREATE_AGENT_TRADE),
    takeEvery(actions.CREATE_TRADE, CREATE_TRADE),
    takeEvery(actions.UPDATE_TRADE_STATUS, UPDATE_TRADE_STATUS),
    takeEvery(actions.CONTINUE_TRADE_STATUS, CONTINUE_TRADE_STATUS),
    takeEvery(actions.ATTENTION_TRADE_STATUS, ATTENTION_TRADE_STATUS),
    takeEvery(actions.UPDATE_ADMIN_TRADE, UPDATE_ADMIN_TRADE),
    takeEvery(actions.TAKE_UP_TRADE, TAKE_UP_TRADE),
    takeEvery(actions.RATE_TRADE, RATE_TRADE),
    takeEvery(actions.REMARKS, REMARKS),
    takeEvery(actions.CHANGE_TOGGLE, CHANGE_TOGGLE),
    takeEvery(actions.TRADE_ANALYTICS, TRADE_ANALYTICS),
    takeEvery(actions.SINGLE_TRADE_ANALYTICS, SINGLE_TRADE_ANALYTICS),
    takeEvery(actions.DOWNLOAD_TRADE_ANALYTICS, DOWNLOAD_TRADE_ANALYTICS),
    takeEvery(actions.SEARCH_TRANSACTIONS, SEARCH_TRANSACTIONS),
  ])
}
