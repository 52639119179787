/* eslint no-underscore-dangle: 0 */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
// import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import {loginLog, logoutLog} from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    logout: jwt.logout,
    forgotPassword: jwt.forgotPassword,
    resetPassword: jwt.resetPassword,
    resendActivationCode: jwt.resendActivationCode,
  },
}

const successModal = (message, title)=>{
	return notification.success({
		message: title,
		description: message,
	  })
}

const warningModal = (message, title)=>{
	return notification.warning({
		message: title,
		description: message,
	  })
}


export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  if (success.status === "success") {
	yield put({
		type: 'user/LOGIN_LOG',
	})
    localStorage.setItem("data", JSON.stringify({
      firstName:success.data.admin.firstName,
      lastName:success.data.admin.lastName,
      email:success.data.admin.email,
      phone:success.data.admin.phone,
      adminType:success.data.admin.adminType,
      deviceId:success.data.admin.deviceId,
      photo:success.data.admin.photo,
      password:success.data.admin.password,
      id:success.data.admin._id,
      role:success.data.admin.role,
      token:success.data.token,
    }))
    localStorage.setItem("role", JSON.stringify(success.data.admin.role));
    yield history.push('/')
    successModal(success.message, 'Logged In');
  }else if(success.status === "error"){
    warningModal(success.message, 'Log In error occur');
  }else{
	warningModal(success.message, 'Log In error occur');
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: true,
      loading: false,
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].forgotPassword, payload)
  if (success.status === "success") {
    yield history.push('/auth/activation')
    successModal(success.message, 'OTP sent');
  }else{
	warningModal(success.message, 'error occur');
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].resetPassword, payload)
  if (success.status === "success") {
    yield history.push('/auth/login')
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
	successModal(success.message, 'Password Changed');
  }else{
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
	warningModal(success.message, 'error occur');
  }
}

export function* LOGIN_LOG() {
	yield put({
	  type: 'user/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	// const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(loginLog)
	console.log(success)
}

export function* LOGOUT_LOG() {
	yield put({
	  type: 'user/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	// const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(logoutLog)
	console.log(success)
	localStorage.removeItem("data");
	localStorage.removeItem("roles");
	localStorage.removeItem("deviceId");
	localStorage.removeItem("email");
}

// export function* REGISTER({ payload }) {
//   const { email, password, name } = payload
//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const { authProvider } = yield select(state => state.settings)
//   const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
//   if (success) {
//     yield put({
//       type: 'user/LOAD_CURRENT_ACCOUNT',
//     })
//     yield history.push('/')
//     notification.success({
//       message: 'Succesful Registered',
//       description: 'You have successfully registered!',
//     })
//   }
//   if (!success) {
//     yield put({
//       type: 'user/SET_STATE',
//       payload: {
//         loading: false,
//       },
//     })
//   }
// }

// export function* LOAD_CURRENT_ACCOUNT() {
//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const { authProvider } = yield select(state => state.settings)
//   const response = yield call(mapAuthProviders[authProvider].currentAccount)
//   if (response) {
//     const { id, email, name, avatar, role } = response
//     yield put({
//       type: 'user/SET_STATE',
//       payload: {
//         id,
//         name,
//         email,
//         avatar,
//         role,
//         authorized: true,
//       },
//     })
//   }
//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: false,
//     },
//   })
// }

export function* RESEND_TOKEN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].resendActivationCode, payload)
  if (success.status === "success") {
	successModal(success.message, 'Password Changed');
  }else{
	warningModal(success.message, 'error occur');
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
	yield put({
		type: 'user/LOGOUT_LOG',
	})
//   const { authProvider } = yield select(state => state.settings)
//   yield call(mapAuthProviders[authProvider].logout)
  yield history.push('/auth/login')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.RESEND_TOKEN, RESEND_TOKEN),
    takeEvery(actions.LOGIN_LOG, LOGIN_LOG),
    takeEvery(actions.LOGOUT_LOG, LOGOUT_LOG),
    takeEvery(actions.LOGOUT, LOGOUT),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
