export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Dashboard',
    //   roles: ['super-admin', 'dashboard-metric'],
    },
    {
      title: 'Dashboard',
      key: 'dashboards',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
	{
		title: 'Analytics',
		key: 'analytics',
		icon: 'fa fa-bar-chart',
		roles: ['super-admin', 'monitor-reviews'],
		children: [
			{
			  title: 'Admin Analytics',
			  key: 'admin-analytics',
			  url: '/admin-analytics',
			},
			{
			  title: 'Trade Analytics',
			  key: 'trade-analytics',
			  url: '/trade-analytics',
			}
		],
	},
    {
      category: true,
      title: 'Administration',
      roles: ['super-admin'],
    },
	{
	  title: 'All Admins',
	  key: 'admins',
	  url: '/admins',
      icon: 'fe fe-database',
      roles: ['super-admin', 'admin-user-crud'],
	},
	{
	  title: 'All Devices',
	  key: 'devices',
	  url: '/admin-devices',
	  icon: 'fa fa-desktop',
	  roles: ['super-admin'],
	},
	{
	  title: 'All Remark',
	  key: 'remark',
	  url: '/remarks',
      icon: 'fa fa-comment',
      roles: ['super-admin'],
	},
	{
	  title: 'All Roles',
	  key: 'roles',
	  url: '/roles',
      icon: 'fa fa-universal-access',
      roles: ['super-admin'],
	},
	{
	  title: 'All Vendors',
	  key: 'vendors',
	  url: '/vendors',
      icon: 'fa fa-user-secret',
      roles: ['super-admin'],
	},
    {
      category: true,
      title: 'Messaging',
	  roles:['super-admin', 'chat', 'broadcast', 'banner']
    },
    {
      title: 'Chat',
      icon: 'fe fe-message-square',
      key: 'chat',
      url: '/chat',
      roles: ['super-admin', "chat"],
    },
	{
		title: 'Broadcast',
		key: 'broadcast',
		icon: 'fe fe-inbox',
		roles: ['super-admin', 'broadcast', 'banner'],
		children: [
			{
			  title: 'Broadcast',
			  key: 'broadcast',
			  url: '/broadcast',
			  roles: ['super-admin', 'broadcast'],
			},
			{
			  title: 'Banner',
			  key: 'banner',
			  url: '/upload-banner',
			  roles: ['super-admin', 'banner'],
			}
		],
	},
    {
      category: true,
      title: 'Features',
    },
    {
      title: 'Withdrawals',
      key: 'withdraws',
      icon: 'fa fa-money',
      url: '/withdrawal-request',
	  roles:['super-admin', 'withdrawal']
    },
    {
      title: 'Tickets',
      key: 'tickets',
      icon: 'fa fa-ticket',
      url: '/tickets',
      roles: ['super-admin', 'ticket-review'],
	},
    {
      title: 'Trades',
      key: 'trading',
      icon: 'fa fa-briefcase',
      url: '/trade',
	  roles:['super-admin', 'admin-sale']
    },
    {
      title: 'BTC',
      key: 'btc',
      icon: 'fa fa-btc',
      url: '/btc',
	  roles:['super-admin', 'rates-management']
    },
    {
      title: 'Cards',
      key: 'cards',
      icon: 'fa fa-credit-card',
      url: '/cards',
	  roles:['super-admin', 'rates-management']
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fa fa-users',
      url: '/users',
	  roles:['super-admin', 'user-account', 'create-users']
    },
    {
	  title: 'Transactions',
	  key: 'transactions',
	  icon: 'fa fa-history',
	  roles: ['super-admin', 'transaction' ],
	  children: [
        {
          title: 'BTC',
          key: 'btc-transaction',
		  url: '/btc-transaction',
        },
		{
		  title: 'Airtime',
		  key: 'airtime',
		  url: '/airtime',
		},
        {
          title: 'Data',
          key: 'data',
          url: '/data',
        },
        {
          title: 'Cable',
          key: 'cable',
          url: '/cable',
        },
        {
          title: 'Electricity',
          key: 'electricity',
          url: '/electricity',
        },
		{
		  title: 'Gift Cards',
		  key: 'gift_cards',
		  url: '/gift-cards',
		},
		{
		  title: 'Withdrawals',
		  key: 'withdrawal',
		  url: '/withdrawal',
		},
      ],
	},
    {
      category: true,
      title: 'Settings',
    },
    {
      title: 'Withdrawal Settings',
      icon: 'fe fe-settings',
      key: 'withdrawal-settings',
      url: '/withdrawal-settings',
    },
    {
      title: 'My Profile',
      icon: 'fe fe-user',
      key: 'profile',
      url: '/profile',
    },
  ]
}
