import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore  } from 'index'
import * as card from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    allRates: card.allRates,
    createRate: card.createRate,
    updateRate: card.updateRate,
    deleteRate: card.deleteRate,
    allGiftCard: card.allGiftCard,
    allCard: card.allCard,
    createGiftCard: card.createGiftCard,
    updateGiftCard: card.updateGiftCard,
    allCountries: card.allCountries,
    disableRate:card.disableRate,
    updateStartWindow:card.updateStartWindow,
  },
}

export function* RATES({payload}) {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allRates, payload)
  if (success.status === "success") {
    yield put({
      type: 'card/SET_STATE',
      payload: {
        rates: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE_RATE({payload}) {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createRate, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'card/RATES',
    })
    notification.success({
      message: 'New card created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* UPDATE_RATE({payload}) {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateRate, payload)
  if (success.state === "success") {
    yield reduxStore.dispatch({
      type: 'card/RATES',
    })
    notification.success({
      message: 'Card rate updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* DISABLE_RATE({payload}) {
	yield put({
	  type: 'card/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].disableRate, payload)
	if (success.status === "success") {
	  yield reduxStore.dispatch({
		type: 'card/RATES',
	  })
	  notification.success({
		message: 'Card rate updated',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'card/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export function* DELETE_RATE({payload}) {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].deleteRate, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'card/RATES',
    })
    notification.success({
      message: 'Card rate deleted',
      description: success.message,
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* ALL_GIFT_CARD() {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allGiftCard)
  if (success.status === "success") {
    yield put({
      type: 'card/SET_STATE',
      payload: {
        giftCards: success.data.giftCardName,
        giftImages: success.data.giftImages.resources,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* ALL_COUNTRY() {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].allCountries)
  if (success.state === "success") {
    yield put({
      type: 'card/SET_STATE',
      payload: {
        countriesName: success.data.countryNames,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* CARDS() {
	yield put({
	  type: 'card/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].allCard)
	if (success.status === "success") {
	  yield put({
		type: 'card/SET_STATE',
		payload: {
		  loading:false,
		  cards:success.data
		},
	  })
	}else{
	  yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
	  })
	  notification.success({
      message: 'Error Occured',
      description: success,
	  })
	}
}

export function* CREATE_GIFT_CARD({payload}) {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].createGiftCard, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'card/CARDS',
    })
    notification.success({
      message: 'New gift card created',
      description: success.message,
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success.message,
    })
  }
}

export function* UPDATE_GIFT_CARD({payload}) {
  yield put({
    type: 'card/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].updateGiftCard, payload)
  if (success.status === "success") {
    yield reduxStore.dispatch({
      type: 'card/CARDS',
    })
    notification.success({
      message: 'Card rate updated',
      description: success.message,
    })
  }else{
    yield put({
      type: 'card/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Error Occured',
      description: success,
    })
  }
}

export function* UPDATE_START_WINDOW({payload}) {
	yield put({
	  type: 'card/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].updateStartWindow, payload)
	if (success.status === "success") {
	  yield reduxStore.dispatch({
		type: 'card/RATES',
	  })
	  notification.success({
		message: 'Card rate updated',
		description: success.message,
	  })
	}else{
	  yield put({
		type: 'card/SET_STATE',
		payload: {
		  loading: false,
		},
	  })
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RATES, RATES),
    takeEvery(actions.CREATE_RATE, CREATE_RATE),
    takeEvery(actions.UPDATE_RATE, UPDATE_RATE),
    takeEvery(actions.DISABLE_RATE, DISABLE_RATE),
    takeEvery(actions.DELETE_RATE, DELETE_RATE),
    takeEvery(actions.ALL_GIFT_CARD, ALL_GIFT_CARD),
    takeEvery(actions.CARDS, CARDS),
    takeEvery(actions.ALL_COUNTRY, ALL_COUNTRY),
    takeEvery(actions.UPDATE_START_WINDOW, UPDATE_START_WINDOW),
    takeEvery(actions.CREATE_GIFT_CARD, CREATE_GIFT_CARD),
    takeEvery(actions.UPDATE_GIFT_CARD, UPDATE_GIFT_CARD),
  ])
}
