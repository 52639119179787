import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore  } from 'index'
import * as device from 'services/jwt/service'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    getAdminDevices: device.getAdminDevices,
	updateAdminDevice: device.updateAdminDevice,
  },
}

// fetch all withdrawals
export function* DEVICES() {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].getAdminDevices)
  if (success.status === "success") {
    yield put({
      type: 'device/SET_STATE',
      payload: {
        devices: success.data,
        all: success.data,
        loading:false
      },
    })
  }else{
    yield put({
      type: 'device/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_ADMIN_DEVICE({payload}) {
	yield put({
	  type: 'device/SET_STATE',
	  payload: {
		loading: true,
	  },
	})
	const { authProvider: autProviderName } = yield select(state => state.settings)
	const success = yield call(mapAuthProviders[autProviderName].updateAdminDevice, payload)
	if (success.status === "success") {
		yield reduxStore.dispatch({
			type: 'device/DEVICES',
		})
		notification.success({
			message: 'device Updated',
			description: success.message,
		})
	}else{
	  notification.success({
		message: 'Error Occured',
		description: success,
	  })
	}
	yield put({
		type: 'device/SET_STATE',
		payload: {
		  loading: false,
		},
	})
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DEVICES, DEVICES),
    takeEvery(actions.UPDATE_ADMIN_DEVICE, UPDATE_ADMIN_DEVICE),
  ])
}
